<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#7EA6F7" />
    <path
      d="M11.0091 9.36173L9.95074 9.2409C9.69657 9.21173 9.44657 9.29923 9.2674 9.4784L8.50074 10.2451C7.32157 9.64506 6.3549 8.68256 5.7549 7.49923L6.52574 6.7284C6.7049 6.54923 6.7924 6.29923 6.76324 6.04506L6.6424 4.99506C6.5924 4.57423 6.23824 4.25756 5.81324 4.25756H5.0924C4.62157 4.25756 4.2299 4.64923 4.25907 5.12006C4.4799 8.6784 7.32574 11.5201 10.8799 11.7409C11.3507 11.7701 11.7424 11.3784 11.7424 10.9076V10.1867C11.7466 9.7659 11.4299 9.41173 11.0091 9.36173Z"
      fill="white"
    />
  </svg>
</template>
