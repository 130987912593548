<template>
  <div class="Profile__wrapper">
    <Avatar :data="avatarProps" />
    <div class="Profile__info">
      <span class="Profile__name">
        {{ props.name }}
      </span>
      <span class="Profile__username"> @{{ props.username }} </span>
    </div>
    <span class="Profile__kingschat">
      <IconKingschat class="primary" />
    </span>
    <NuxtLink to="/logout" class="Profile__logout-button"> Log out </NuxtLink>
  </div>
</template>
<script setup lang="ts">
type Props = {
  username: string;
  name: string;
  avatarUrl: string | null;
};
const props = defineProps<Props>();

const avatarProps = computed(() => {
  if (props.avatarUrl && props.avatarUrl.length > 0) {
    return {
      variant: "IMAGE",
      size: "40px",
      imageUrl: props.avatarUrl,
    } as const;
  }
  return {
    variant: "USERNAME",
    size: "40px",
    username: props.username,
  } as const;
});
</script>
<style scoped lang="scss">
.Profile {
  &__wrapper {
    border: 1px solid $color-neutral-300;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    column-gap: 12px;
    position: relative;
    margin-top: 16px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
  }
  &__name {
    @include TitleSmall;
    color: $color-element-dark-strong;
  }
  &__username {
    @include BodyMedium;
    color: $color-element-dark-basic;
  }
  &__kingschat {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $color-basic-white;
    svg {
      width: 19px;
    }
  }
  &__logout-button {
    @include TitleXSmall;
    background: none;
    padding: 0;
    border: none;
    text-align: left;
    color: $color-basic-error;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    margin-top: 16px;
  }
}
</style>
