<template>
  <button
    class="Offer__container"
    :class="selectedClass"
    @click="handleChooseOffer"
  >
    <div class="Offer__header" :class="roomType">
      <div class="Offer__select" :class="selectedClass"></div>
      <h3 class="Offer__title">{{ name }}</h3>
      <div class="Offer__price">{{ formattedPrice }} {{ currency }}</div>
    </div>
    <ul class="Offer__features" :class="selectedClass">
      <li
        v-for="(feature, index) in features"
        :key="index"
        class="Offer__feature"
      >
        <o-icon :icon="feature.icon" custom-size="mdi-18px" />
        {{ feature.text }}
      </li>
    </ul>
  </button>
</template>

<script setup lang="ts">
type Props = {
  selectedId: string | null;
  id: string;
  name: string;
  price: number;
  currency: string;
  maxParticipants: number;
  maxDurationInHours: number;
  roomType: "audio" | "video" | "streamed";
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "chooseOffer", id: string): void;
}>();

const handleChooseOffer = () => {
  emit("chooseOffer", props.id);
};

const selected = computed(() => {
  return props.selectedId === props.id;
});

const selectedClass = computed(() => {
  return selected.value ? props.roomType : "";
});

const formattedPrice = computed(() => {
  return (props.price / 100).toFixed(2);
});

const features = computed(() => {
  return [
    {
      icon: "account-multiple",
      text: `Up to ${props.maxParticipants} people`,
    },
    {
      icon: "clock-time-five-outline",
      text: `Up to ${props.maxDurationInHours} hours long per session`,
    },
  ];
});
</script>

<style scoped lang="scss">
.Offer {
  &__container {
    display: flex;
    flex-direction: column;
    border: none;
    background: none;
    width: 100%;
    padding: 0;
    border-radius: 12px;
    cursor: pointer;
    border: 2px solid transparent;
    &.audio {
      border: 2px solid $brand-tertiary-500;
    }
    &.video {
      border: 2px solid $brand-primary-500;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    padding: 10px 12px;
    width: 100%;
    border-radius: 12px 12px 0 0;
    &.audio {
      background-color: $brand-tertiary-050;
      color: $brand-tertiary-500;
    }
    &.video {
      background-color: $brand-primary-050;
      color: $brand-primary-500;
    }
  }
  &__select {
    width: 24px;
    height: 24px;
    border-radius: 999px;
    border: 1px solid $color-neutral-600;
    &.audio {
      border: 7px solid $brand-tertiary-500;
    }
    &.video {
      border: 7px solid $brand-primary-500;
    }
  }
  &__title {
    @include BodyLarge;
    color: $color-element-dark-strong;
    margin: 0;
  }

  &__price {
    @include TitleSmall;
    color: inherit;
    margin-left: auto;
  }

  &__features {
    list-style: none;
    padding: 12px;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid $color-neutral-300;
    border-top: none;
    border-radius: 0 0 12px 12px;
    width: 100%;
    &.audio,
    &.video {
      border-color: transparent;
    }
  }

  &__feature {
    @include BodyMedium;
    color: $color-element-dark-basic;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    .o-icon {
      color: $color-element-dark-weak;
    }
  }
}
</style>
