<template>
  <button class="Profile__container" @click="handleClick">
    <div class="Profile__wrapper">
      <Avatar :data="avatarProps" />
    </div>
    <div class="Profile__chevron">
      <o-icon custom-size="mdi-18px" icon="chevron-down"></o-icon>
    </div>
  </button>
</template>
<script setup lang="ts">
type Props = {
  avatarUrl: string | null;
  username: string;
};
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "click"): void;
}>();

const handleClick = () => {
  emit("click");
};
const avatarProps = computed(() => {
  if (props.avatarUrl && props.avatarUrl.length > 0) {
    return {
      variant: "IMAGE",
      size: "34px",
      imageUrl: props.avatarUrl,
    } as const;
  }
  return {
    variant: "USERNAME",
    size: "34px",
    username: props.username,
  } as const;
});
</script>
<style scoped lang="scss">
.Profile {
  &__container {
    border-radius: 999px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    padding: 6px 10px;
    background: $color-neutral-050;
  }
  &__wrapper {
    border-radius: 50%;
    border: 2px solid $color-element-light-strong;
  }
  &__chevron {
    display: flex;
  }
}
</style>
