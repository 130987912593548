import { defineStore, skipHydrate } from "pinia";
import { useStorage, useMediaQuery } from "@vueuse/core";
import { useDashboardStore } from "./dashboard";
import { useBetaTesterStatusStore } from "./betaTesterStatus";

type DashboardSteps =
  | "FINISHED"
  | "START"
  | "NEW_HOMEPAGE"
  | "ROOMS"
  | "YOUR_SPACE"
  | "DIFFERENT_ROOMS"
  | "SETTINGS"
  | "SKIPPED";

type DashboardState = { currentStep: DashboardSteps };

export const useDashboardOnboardingStore = defineStore(
  "dashboard-onboarding",
  () => {
    const dashboardStore = useDashboardStore();
    const isMobile = useMediaQuery("(max-width: 724px)");
    const betaTesterStatus = useBetaTesterStatusStore();

    const analytics = useAnalytics();

    const dashboardState = useStorage<DashboardState>(
      "dashboard-onboarding-state",
      {
        currentStep: "START",
      },
    );

    watch(
      () => betaTesterStatus.isBetaTester,
      (newValue) => {
        if (newValue && dashboardState.value.currentStep === "START") {
          dashboardState.value.currentStep = "FINISHED";
        }
      },
    );

    const nextStep = () => {
      switch (dashboardState.value.currentStep) {
        case "START":
          dashboardState.value.currentStep = "NEW_HOMEPAGE";
          break;
        case "NEW_HOMEPAGE":
          dashboardState.value.currentStep = "ROOMS";
          break;
        case "ROOMS":
          dashboardState.value.currentStep = "YOUR_SPACE";
          break;
        case "YOUR_SPACE":
          dashboardState.value.currentStep = "DIFFERENT_ROOMS";
          break;
        case "DIFFERENT_ROOMS":
          dashboardState.value.currentStep = "SETTINGS";
          break;
        case "SETTINGS":
        case "SKIPPED":
          dashboardState.value.currentStep = "FINISHED";
          break;
      }
    };

    const skipDashboardOnboarding = () => {
      dashboardState.value.currentStep = "SKIPPED";
    };

    const restartOnboarding = () => {
      analytics.trackOtherEvent("onboarding_restart");
      dashboardStore.handleSettingsClose();
      dashboardState.value.currentStep = "START";
    };

    watch(
      () => dashboardState.value.currentStep,
      (newValue, oldValue) => {
        if (newValue === oldValue) {
          return;
        }
        switch (newValue) {
          case "ROOMS": {
            if (!isMobile.value) {
              setTimeout(() => {
                const element = document.getElementsByClassName("step-2")[0];
                element.scrollIntoView({ block: "center", behavior: "smooth" });
              }, 300);
            }
            break;
          }
          case "YOUR_SPACE": {
            dashboardStore.mobileActiveTab = "MY_ROOMS";
            setTimeout(() => {
              const element = document.getElementsByClassName("step-3")[0];
              element.scrollIntoView({ block: "center", behavior: "smooth" });
            }, 300);
            break;
          }
          case "DIFFERENT_ROOMS": {
            if (isMobile.value) {
              dashboardStore.mobileActiveTab = "MY_ROOMS";
            }
            setTimeout(() => {
              const element = document.getElementsByClassName("step-4")[0];
              element.scrollIntoView({ block: "center", behavior: "smooth" });
            }, 300);
            break;
          }
          case "FINISHED":
          case "SKIPPED": {
            if (isMobile.value) {
              return;
            }
            navigateTo("/dashboard");
            break;
          }
        }
      },
    );

    return {
      dashboardState: skipHydrate(dashboardState),
      nextStep,
      skipDashboardOnboarding,
      restartOnboarding,
    };
  },
);
