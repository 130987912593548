<template>
  <CommonDetailsWrapper
    ref="wrapper"
    wrapper-class="schedule"
    @close="handleClose"
    @go-back="handleClose"
  >
    <template #customHeader>
      <button class="ScheduleMeeting__close" @click="handleClose">
        <IconClose />
      </button>
      Invite from KingsChat
    </template>
    <template #content>
      <div class="ScheduleMeeting__wrapper">
        <div v-show="step === 'INITIAL'" class="ScheduleMeeting__step-2">
          <FormSearch ref="search" label="Search" placeholder="Search" />
          <ul
            v-if="contactsState.data.length > 0"
            class="ScheduleMeeting__participants-list"
          >
            <li
              v-for="(contact, index) in contactsState.data"
              :key="index"
              class="ScheduleMeeting__participants-list-element"
            >
              <DashboardListParticipant v-bind="contact" />
              <FormCheckbox
                ref="checkboxes"
                :key="getCheckedValue(contact.id)"
                :value-when-true="true"
                :initial-value="getCheckedValue(contact.id)"
                @update:model-value="
                  (value) => handleUpdateModelValue(value, contact.id)
                "
              />
            </li>
          </ul>
        </div>
        <div v-show="step === 'SUCCESS'" class="ScheduleMeeting__step-3">
          <div class="ScheduleMeeting__illustration-wrapper">
            <IllustrationActionSuccess />
            <h3 class="ScheduleMeeting__illustration-heading">
              Invitations have been sent
            </h3>
            <p class="ScheduleMeeting__illustration-description">
              Invitations have been sent to participants.
            </p>
          </div>
        </div>
        <div v-show="step === 'ERROR'" class="ScheduleMeeting__step-4">
          <div class="ScheduleMeeting__illustration-wrapper">
            <DashboardIllustrationError />
            <h3 class="ScheduleMeeting__illustration-heading">
              Something went wrong
            </h3>
            <p class="ScheduleMeeting__illustration-description">
              We couldn't schedule your meeting. Please try again.
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div v-show="step === 'INITIAL'" class="ScheduleMeeting__button-wrapper">
        <button
          :class="{ disabled: checkedNumber === 0 }"
          class="ScheduleMeeting__button"
          @click="handleInviteParticipants"
        >
          Send invites
        </button>
      </div>
      <div
        v-show="step === 'SUCCESS' || step === 'ERROR'"
        class="ScheduleMeeting__button-wrapper"
      >
        <button class="ScheduleMeeting__button" @click="handleClose">
          Return to home screen
        </button>
      </div>
    </template>
  </CommonDetailsWrapper>
</template>
<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { ref, computed } from "vue";
import { watchThrottled } from "@vueuse/core";
import { ContactsResponse, Profile } from "@/types";

type Props = {
  meetingId: string;
};

type ContactsState = {
  status: "LOADED" | "LOADING" | "INITIAL";
  nextToken: string;
  data: Profile[];
};

const runtimeConfig = useRuntimeConfig();

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const handleClose = () => {
  emit("close");
};

const wrapper = ref<{ containerRef: HTMLElement | null }>({
  containerRef: null,
});

type Input = { value: string } | null;

const step = ref<"INITIAL" | "SUCCESS" | "ERROR">("INITIAL");

const search = ref<Input>(null);
const checked = ref(new Map());
const checkboxes = ref([]);

const contactsState = ref<ContactsState>({
  status: "INITIAL",
  nextToken: "",
  data: [],
});

const contactsUrl = computed(() => {
  const url = new URL(
    `${runtimeConfig.public.backendApiUrl}/users/kingschat_contacts`,
  );
  url.searchParams.set("next_token", contactsState.value.nextToken);
  if (search.value?.value) {
    url.searchParams.set("q", `${search.value.value}`);
  }
  return url;
});

const fetchContacts = async () => {
  if (contactsState.value.status === "LOADING") {
    return;
  }
  contactsState.value.status = "LOADING";
  return await useAuthRequest<ContactsResponse>(contactsUrl.value.href)
    .then((res) => {
      if (res.data.value.profiles.length > 0) {
        contactsState.value.data.push(...res.data.value.profiles);
      }
      contactsState.value.nextToken = res.data.value.next_token;
      contactsState.value.status = "LOADED";
    })
    .catch((err) => {
      Sentry.captureException(err);
      contactsState.value.status = "LOADED";
    });
};

const handleUpdateModelValue = computed(() => {
  return (value: boolean, id: string) => {
    checked.value.set(id, value);
  };
});

const getCheckedValue = computed(() => {
  return (id: string) => {
    return checked.value.get(id) || false;
  };
});

const checkedNumber = computed(() => {
  return [...checked.value].filter(([_key, value]) => value).length;
});

const handleInviteParticipants = async () => {
  const invited = [...checked.value]
    .filter(Boolean)
    .map(([key, _value]) => key);
  const payload = {
    users_ids: invited,
  };
  await useAuthRequest(
    `${runtimeConfig.public.backendApiUrl}/meetings/${props.meetingId}/invite_users`,
    {
      method: "POST",
      body: payload,
    },
  ).then((res) => {
    if (!res.pending.value && res.error.value) {
      step.value = "ERROR";
    }
    if (!res.pending.value && !res.error.value) {
      step.value = "SUCCESS";
    }
  });
};

watchThrottled(
  () => search.value?.value,
  async (newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }
    contactsState.value.data = [];
    await fetchContacts();
  },
  {
    throttle: 1500,
  },
);

useInfiniteScroll(
  () => wrapper.value?.containerRef || null,
  async () => {
    await fetchContacts();
  },
  {
    distance: 100,
    canLoadMore: () => {
      if (
        contactsState.value.status === "LOADED" &&
        contactsState.value.nextToken === ""
      ) {
        return false;
      }
      return true;
    },
  },
);
</script>
<style scoped lang="scss">
.ScheduleMeeting {
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    height: calc(100% - 16px);
    min-height: calc(100% - 16px);
  }
  &__close {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__step-indicator {
    @include label-l;
    font-variant-numeric: tabular-nums;
    position: absolute;
    padding: 8px 12px;
    color: $color-element-light-strong;
    background: $color-element-dark-disabled;
    border-radius: 12px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &__step-1 {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  &__step-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__invite-header {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    margin-bottom: 16px;
    align-items: baseline;
  }
  &__invite-header-text {
    @include header-s;
    color: $color-element-dark-strong;
  }
  &__invite-header-description {
    @include body-l;
    color: $color-element-dark-medium;
  }
  &__illustration-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  &__illustration-heading {
    @include header-m;
    color: $color-element-dark-strong;
    margin-top: 4px;
    margin-bottom: 16px;
  }
  &__illustration-description {
    @include body-l;
    color: $color-element-dark-medium;
    text-align: center;
  }
  &__share-wrapper {
    margin-top: auto;
  }
  &__button-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border-top: 1px solid $color-neutral-300;
  }
  &__button {
    @include button-primary;
    display: row;
    column-gap: 8px;
    &.disabled {
      pointer-events: none;
      background: $color-neutral-200;
      color: $color-element-dark-disabled;
    }
  }
  &__participants-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: 0;
    padding: 0;
    margin-top: 24px;
  }
  &__participants-list-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
