<template>
  <div class="RoomSelection__container">
    <div class="RoomSelection__header">
      <h1 class="RoomSelection__title">Select room type</h1>
      <DashboardAddRoomCurrencySelection />
    </div>
    <div class="RoomSelection__cards">
      <button
        v-if="priceAudio"
        class="RoomSelection__card audio"
        @click="handleChooseRoomType('audio')"
      >
        <div class="RoomSelection__icon-wrapper audio">
          <o-icon icon="phone" custom-size="mdi-24px" />
        </div>
        <h2 class="RoomSelection__card-title">Audio Room</h2>
        <div class="RoomSelection__pricing">
          <span class="RoomSelection__price audio">
            {{ audioPriceText }}
          </span>
          <span class="RoomSelection__period">per host/monthly</span>
        </div>
        <div class="RoomSelection__button audio">
          Choose room size
          <o-icon icon="arrow-right" custom-size="mdi-24px" />
        </div>
      </button>
      <button
        v-if="priceVideo"
        class="RoomSelection__card video"
        @click="handleChooseRoomType('video')"
      >
        <div class="RoomSelection__icon-wrapper video">
          <o-icon icon="video" custom-size="mdi-24px" />
        </div>
        <h2 class="RoomSelection__card-title">Video Room</h2>
        <div class="RoomSelection__pricing">
          <span class="RoomSelection__price video">
            {{ videoPriceText }}
          </span>
          <span class="RoomSelection__period">per host/monthly</span>
        </div>
        <div class="RoomSelection__button video">
          Choose room size
          <o-icon icon="arrow-right" custom-size="mdi-24px" />
        </div>
      </button>
    </div>
    <div class="RoomSelection__features">
      <div class="RoomSelection__feature">
        <o-icon icon="creation" />
        <span>Seamless video or audio conferencing</span>
      </div>
      <div class="RoomSelection__feature">
        <o-icon icon="account-multiple" />
        <span>Up to 1000 participants</span>
      </div>
      <div class="RoomSelection__feature">
        <o-icon icon="forum" />
        <span>Dynamic in-meeting chat & reactions</span>
      </div>
      <div class="RoomSelection__feature">
        <o-icon icon="cog" />
        <span>Manage participants easily</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCurrencyStore } from "~/store/currency";

const props = defineProps<{
  priceAudio?: number;
  priceVideo?: number;
}>();

const currencyStore = useCurrencyStore();

const audioPriceText = computed(() => {
  const price = props.priceAudio ? (props.priceAudio / 100).toFixed(2) : null;
  if (!price) {
    return "";
  }

  return `from ${price} ${currencyStore.defaultCurrency.currency.code}`;
});

const videoPriceText = computed(() => {
  const price = props.priceVideo ? (props.priceVideo / 100).toFixed(2) : null;
  if (!price) {
    return "";
  }

  return `from ${price} ${currencyStore.defaultCurrency.currency.code}`;
});

const emit = defineEmits<{
  (e: "chooseRoomType", type: "audio" | "video"): void;
}>();

const handleChooseRoomType = (type: "audio" | "video") => {
  emit("chooseRoomType", type);
};
</script>
<style scoped lang="scss">
.RoomSelection {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    @include HeadlineSmall;
    color: $color-element-dark-strong;
  }
  &__price-selector {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $color-element-dark-medium;
    width: 132px;
  }
  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 12px;
  }
  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    border-radius: 12px;
    row-gap: 16px;
    border: none;
    cursor: pointer;
    &.audio {
      background: $brand-tertiary-050;
    }
    &.video {
      background: $brand-primary-050;
    }
    &:hover {
      .RoomSelection__button {
        &.audio {
          background: $brand-tertiary-700;
        }
        &.video {
          background: $brand-primary-700;
        }
      }
    }
  }
  &__icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-element-light-strong;
    &.audio {
      background: $brand-tertiary-500;
    }
    &.video {
      background: $brand-primary-500;
    }
  }
  &__card-title {
    @include TitleMedium;
    color: $color-element-dark-strong;
  }
  &__pricing {
    display: flex;
    flex-direction: column;
  }
  &__price {
    @include TitleSmall;
    &.audio {
      color: $brand-tertiary-500;
    }
    &.video {
      color: $brand-primary-500;
    }
  }
  &__period {
    @include BodySmall;
    color: $color-element-dark-medium;
  }
  &__button {
    @include button-primary;
    @include LabelSmall;
    padding: 6px 9px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    justify-content: center;
    @include md {
      @include LabelMedium;
    }
    &.audio {
      background: $brand-tertiary-500;
      transition: background 0.3s ease;
    }
    &.video {
      background: $brand-primary-500;
      transition: background 0.3s ease;
    }
  }
  &__features {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__feature {
    @include BodyLarge;
    display: flex;
    align-items: center;
    gap: 8px;
    color: $color-element-dark-medium;
    .o-icon {
      color: $color-element-dark-weak;
    }
  }
}
</style>
