<template>
  <CommonBottomDrawer wrapper-class="renew" @close="handleClose">
    <template #header> Renew your room </template>
    <template #content>
      <div class="RoomOffer__wrapper">
        <DashboardRoomOfferTitle
          v-if="room.data.value"
          :room-type="room.data.value?.room_type"
          :name="room.data.value?.name"
          :expired="room.data.value?.status === 'expired'"
        >
          <template #label>
            <h4
              class="RoomOffer__label"
              :class="{ expired: room.data.value?.status === 'expired' }"
            >
              {{ expireWarning }}
            </h4>
          </template>
        </DashboardRoomOfferTitle>
        <div v-if="offerType.data.value" class="RoomOffer__content">
          <div class="RoomOffer__filters">
            <DashboardAddRoomFilter
              :selected="selectedPeriodValue"
              :wrapper-class="offerType.data.value.room_type"
              :annual-discount="offerWithChosenCurrency?.percentage_discount"
              @select="handleFilterChange"
            />
            <DashboardAddRoomCurrencySelection />
          </div>
          <div class="RoomOffer__offer">
            <DashboardAddRoomOffer
              v-if="offerWithChosenCurrency"
              :id="offerWithChosenCurrency.id"
              :name="offerType.data.value.name"
              :price="offerWithChosenCurrency.price"
              :currency="offerWithChosenCurrency.currency"
              :max-participants="offerType.data.value.max_participants"
              :max-duration-in-hours="
                offerType.data.value.max_duration_in_sec / 3600
              "
              :room-type="offerType.data.value.room_type"
              :selected-id="offerWithChosenCurrency.id"
            />
          </div>
        </div>
        <button class="RoomOffer__button" @click="handlePayNowClick">
          <DashboardIconKingspay /> Pay now with KingsPay
        </button>
      </div>
    </template>
  </CommonBottomDrawer>
</template>
<script setup lang="ts">
import { DateTime } from "luxon";
import { useCurrencyStore } from "~/store/currency";
import { useDashboardStore } from "~/store/dashboard";
import { OfferType, RoomDetailsResponse } from "~/types";
const runtimeConfig = useRuntimeConfig();

type Props = {
  roomId: string;
};

const emit = defineEmits<{
  (e: "close"): void;
}>();

const props = defineProps<Props>();
const currencyStore = useCurrencyStore();
const dashboardStore = useDashboardStore();

const selectedPeriodValue = ref<"monthly" | "annual">("monthly");

const handleFilterChange = (value: "monthly" | "annual") => {
  dashboardStore.handleClearRoomOffer();
  selectedPeriodValue.value = value;
};

const room = await useAuthRequest<RoomDetailsResponse>(
  `${runtimeConfig.public.backendApiUrl}/rooms/${props.roomId}`,
);
const offerType = await useAuthRequest<OfferType>(
  `${runtimeConfig.public.backendApiUrl}/rooms/${props.roomId}/offers
`,
);

const roomExpirationDateTime = computed(() => {
  return DateTime.fromISO(room.data.value.expiration_datetime, {
    zone: "utc",
  }).toLocal();
});

const expireWarning = computed(() => {
  if (!room.data.value) {
    return "";
  }
  if (room.data.value.status === "expired") {
    return "Expired";
  }
  if (room.data.value.status === "ready_to_renew") {
    return `Expires ${roomExpirationDateTime.value.toRelativeCalendar()}`;
  }
  return "";
});

const offerWithChosenCurrency = computed(() => {
  if (!offerType.data.value) {
    return;
  }
  return offerType.data.value.offers
    .filter((offer) => {
      return offer.subscription_type === selectedPeriodValue.value;
    })
    .find(
      (offer) => offer.currency === currencyStore.defaultCurrency.currency.code,
    );
});

const handleClose = () => {
  emit("close");
};

const handlePayNowClick = () => {
  if (!offerWithChosenCurrency.value) {
    return;
  }
  dashboardStore.handleRenewRoom(
    props.roomId,
    offerWithChosenCurrency.value.id,
    offerWithChosenCurrency.value.currency,
  );
};
</script>
<style scoped lang="scss">
.RoomOffer {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__title-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  &__title-text {
    @include header-m;
    color: $color-element-dark-strong;
  }
  &__label {
    @include label-m;
    padding: 8px 12px;
    color: $color-basic-danger-500;
    background: $color-basic-danger-100;
    border-radius: 12px;
    &.expired {
      color: $color-element-dark-medium;
      background: $color-neutral-100;
    }
  }
  &__button {
    @include button-primary;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    @include md {
      margin-top: 32px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  &__filters {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 12px;
    align-items: center;
    justify-content: center;
    @include md {
      justify-content: space-between;
      flex-direction: row;
    }
  }
}
</style>
