<template>
  <div class="End__container">
    <div class="End__wrapper">
      <div class="End__header">
        <button
          class="End__button-close"
          @click="dashboardOnboardingStore.nextStep"
        >
          <o-icon custom-size="mdi-16px" icon="close" />
        </button>
      </div>
      <div class="End__body">
        <h3 class="End__title">Maybe you'll change your mind</h3>
        <p class="End__text">
          Here you'll now find all the important actions, and it is there that
          you will be able to restart the onboarding guide!
        </p>
      </div>
      <button
        class="End__button-next"
        @click="dashboardOnboardingStore.nextStep"
      >
        Got it
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useDashboardOnboardingStore } from "@/store/dashboardOnboarding";

const dashboardOnboardingStore = useDashboardOnboardingStore();
</script>
<style scoped lang="scss">
.End {
  &__container {
    background: $brand-primary-600;
    border-radius: 8px;
    position: absolute;
    top: 88px;
    right: 40px;
    width: calc(100% - 48px);
    z-index: 1;
    max-width: 318px;
    &::after {
      content: "";
      position: absolute;
      border-width: 8px;
      border-style: solid;
      bottom: 100%;
      right: 10px;
      border-color: transparent transparent $brand-primary-600 transparent;
    }
    @include md {
      right: calc(50% - 340px);
    }
    @include custom-min-width(1163px) {
      left: max(335px, 50% - 257px);
      top: 294px;
      z-index: 2;
      &::after {
        top: 12px;
        left: -16px;
        right: unset;
        border-color: transparent $brand-primary-600 transparent transparent;
      }
    }
  }
  &__wrapper {
    padding: 12px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &__current-step {
    @include LabelSmall;
    color: $color-element-light-basic;
  }
  &__button-close {
    color: $color-element-light-basic;
    border: none;
    background: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    padding: 0;
  }
  &__title {
    @include TitleMedium;
    color: $color-element-light-strong;
  }
  &__text {
    @include BodyLarge;
    color: $color-element-light-strong;
  }
  &__button-next {
    @include button-primary;
    color: $brand-primary-600;
    background: $color-element-light-strong;
    width: fit-content;
    display: flex;
    margin-top: 12px;
    margin-left: auto;
  }
}
</style>
