<template>
  <div class="ListParticipant__wrapper">
    <Avatar :data="avatarProps" />
    <div class="ListParticipant__content-wrapper">
      <p class="ListParticipant__name">{{ name }}</p>
      <p class="ListParticipant__username">{{ username }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Profile } from "~/types";
type Props = Profile;
const props = defineProps<Props>();

const avatarProps = computed(() => {
  if (props.avatar_url && props.avatar_url.length > 0) {
    return {
      variant: "IMAGE",
      size: "40px",
      imageUrl: props.avatar_url,
    } as const;
  }
  return {
    variant: "USERNAME",
    size: "40px",
    username: props.username,
  } as const;
});
</script>
<style scoped lang="scss">
.ListParticipant {
  &__wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: center;
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__name {
    @include label-xl;
    color: $color-element-dark-medium;
  }
  &__username {
    @include label-l;
    color: $color-element-dark-basic;
  }
}
</style>
