<template>
  <div class="CurrencySelection__wrapper">
    <div v-if="isOpen" class="CurrencySelection__overlay"></div>
    <div
      v-on-click-outside="handleCloseList"
      class="CurrencySelection__content"
    >
      <button class="CurrencySelection__button" @click="handleToggleList">
        Price in:
        <span class="CurrencySelection__currency"
          >{{ currencies[currencyStore.defaultCurrency.currency.code].name }}
          <o-icon custom-size="mdi-18px" icon="chevron-down" />
        </span>
      </button>
      <div class="CurrencySelection__list" :class="{ open: isOpen }">
        <button
          class="CurrencySelection__item"
          @click="handleChooseCurrency('ESP')"
        >
          <o-icon :icon="currencies.ESP.icon" custom-size="mdi-24px" />
          {{ currencies.ESP.name }}
          <span
            v-if="currencyStore.defaultCurrency.currency.code === 'ESP'"
            class="CurrencySelection__check-icon"
          >
            <o-icon custom-size="mdi-18px" icon="check" />
          </span>
        </button>
        <button
          class="CurrencySelection__item"
          @click="handleChooseCurrency('USD')"
        >
          <o-icon :icon="currencies.USD.icon" custom-size="mdi-24px" />
          {{ currencies.USD.name }}
          <span
            v-if="currencyStore.defaultCurrency.currency.code === 'USD'"
            class="CurrencySelection__check-icon"
          >
            <o-icon custom-size="mdi-18px" icon="check" />
          </span>
        </button>
        <button
          class="CurrencySelection__item"
          @click="handleChooseCurrency('GBP')"
        >
          <o-icon :icon="currencies.GBP.icon" custom-size="mdi-24px" />
          {{ currencies.GBP.name }}
          <span
            v-if="currencyStore.defaultCurrency.currency.code === 'GBP'"
            class="CurrencySelection__check-icon"
          >
            <o-icon custom-size="mdi-18px" icon="check" />
          </span>
        </button>
        <button
          class="CurrencySelection__item"
          @click="handleChooseCurrency('NGN')"
        >
          <o-icon :icon="currencies.NGN.icon" custom-size="mdi-24px" />
          {{ currencies.NGN.name }}
          <span
            v-if="currencyStore.defaultCurrency.currency.code === 'NGN'"
            class="CurrencySelection__check-icon"
          >
            <o-icon custom-size="mdi-18px" icon="check" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components";
import { useCurrencyStore } from "~/store/currency";

const currencyStore = useCurrencyStore();

const isOpen = ref(false);

const currencies = {
  NGN: { name: "Naira", icon: "currency-ngn" },
  USD: { name: "U.S. Dollar", icon: "currency-usd" },
  GBP: { name: "Pound", icon: "currency-gbp" },
  ESP: { name: "Espees", icon: "circle-multiple-outline" },
};

const handleChooseCurrency = (option: string) => {
  isOpen.value = false;
  const curr = currencyStore.currencies.find((item) => item.code === option);
  if (curr) {
    currencyStore.setDefaultCurrency(curr);
  }
};

const handleToggleList = () => {
  isOpen.value = !isOpen.value;
};

const handleCloseList = () => {
  isOpen.value = false;
};
</script>
<style scoped lang="scss">
.CurrencySelection {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__overlay {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-basic-overlay-dark-30;
  }
  &__content {
    position: relative;
    width: 185px;
  }
  &__button {
    @include LabelMedium;
    color: $color-element-dark-medium;
    border: none;
    padding: 12px 16px;
    border-radius: 12px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: $color-neutral-050;
    z-index: 2;
    cursor: pointer;
  }
  &__currency {
    @include TitleSmall;
    color: $color-element-dark-medium;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    width: 95px;
    justify-content: flex-end;
    margin-left: auto;
    .o-icon {
      margin-left: auto;
    }
  }
  &__list {
    display: none;
    flex-direction: column;
    position: absolute;
    background: $color-neutral-050;
    row-gap: 10px;
    padding: 24px 16px;
    width: 100%;
    top: 54px;
    left: 0;
    gap: 10px;
    border-radius: 12px;

    &.open {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
  &__item {
    @include LabelLarge;
    color: $color-element-dark-strong;
    padding: 8px;
    cursor: pointer;
    border: none;
    background: none;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    width: 100%;
    .o-icon {
      color: $color-element-dark-disabled;
    }
  }
  &__check-icon {
    margin-left: auto;
    .o-icon {
      color: $color-basic-success-500;
    }
  }
}
</style>
