<template>
  <button class="FloatingSchedule__wrapper" @click="handleClick">
    <o-icon icon="calendar" />
    Schedule Meeting
  </button>
</template>
<script setup lang="ts">
const emit = defineEmits<{
  (e: "click"): void;
}>();

const handleClick = () => {
  emit("click");
};
</script>
<style scoped lang="scss">
.FloatingSchedule {
  &__wrapper {
    @include label-xl;
    background: $brand-primary-100;
    color: $color-element-dark-strong;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    padding: 17px 20px;
    cursor: pointer;
    border-radius: 16px;
    box-shadow: 0px 1px 3px 0px #25272f4d;
    box-shadow: 0px 4px 8px 3px #25272f26;
    border: none;
  }
}
</style>
