<template>
  <o-field>
    <o-switch
      v-model="modelValue"
      position="left"
      :disabled="disabled"
      @update:model-value="emitUpdateModelValue"
    >
      {{ label }}
    </o-switch>
  </o-field>
</template>
<script setup lang="ts">
import { ref } from "vue";
type Value = boolean;

type Props = {
  label?: string;
  disabled?: boolean;
  initialValue?: Value;
};
const props = defineProps<Props>();
const modelValue = ref(props.initialValue);
const emit = defineEmits(["update:modelValue"]);
const emitUpdateModelValue = (value: Value) => {
  emit("update:modelValue", value);
};
defineExpose({ value: modelValue });
</script>
