<template>
  <CommonBottomDrawer @close="handleClose">
    <template #header
      ><span class="InstantMeeting__heading">Instant Meeting</span>
    </template>
    <template #content>
      <div class="InstantMeeting__container">
        <div class="InstantMeeting__wrapper">
          <DashboardRoomTile
            v-for="room in rooms"
            :key="room.id"
            v-bind="room"
            variant="INSTANT-MEETING"
            @tile-click="handleTileClick"
            @renew-click="dashboardStore.handleRenewOpen"
          />
        </div>
        <button class="InstantMeeting__button" @click="handleAddMoreRoomsClick">
          <o-icon icon="plus-circle-outline" />
          Add more rooms
        </button>
      </div>
    </template>
  </CommonBottomDrawer>
</template>
<script setup lang="ts">
import { useDashboardStore } from "~/store/dashboard";
import { ActiveSession, Room } from "~/types";

type Props = {
  rooms: (Room & { session: ActiveSession | null })[];
};
const props = defineProps<Props>();
const dashboardStore = useDashboardStore();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "add-room"): void;
}>();
const handleClose = () => {
  emit("close");
};

const handleAddMoreRoomsClick = () => {
  emit("add-room");
};

const handleTileClick = (id: string) => {
  const room = props.rooms.find((item) => item.id === id);
  if (room) {
    navigateTo(`/room/${room.private_url}`, { external: true });
  }
};
</script>
<style scoped lang="scss">
.InstantMeeting {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  &__heading {
    text-align: center;
    width: 100%;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 12px;
    column-gap: 12px;
    width: 100%;
    @include sm {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__button {
    @include label-xl;
    color: $color-primary;
    width: 100%;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    padding: 11px 18px;
  }
}
</style>
