<template>
  <CommonBottomDrawer @close="handleClose">
    <template #header
      ><div class="EnterRoom__heading-wrapper">
        <span class="EnterRoom__name"
          ><component
            :is="iconComponent"
            v-bind="{ expired: status === 'expired' }"
          />
          {{ name }}</span
        >
        <div class="EnterRoom__info">
          <span class="EnterRoom__participants"
            >{{ currentParticipants }}/{{ max_participants }}</span
          >
          <span
            v-if="roomLabel"
            class="EnterRoom__label"
            :class="roomLabel.class"
            >{{ roomLabel.text }}</span
          >
        </div>
      </div>
    </template>
    <template #content>
      <div class="EnterRoom__wrapper">
        <button class="EnterRoom__button" @click="handleJoinClick">
          {{ joinText }}
        </button>
        <button
          class="EnterRoom__button secondary"
          @click="handleScheduleClick"
        >
          Schedule for later
        </button>
      </div>
    </template>
  </CommonBottomDrawer>
</template>
<script setup lang="ts">
import { ActiveSession, Room } from "~/types";

type Props = Room & { session: ActiveSession | null };

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "schedule-click", id: string, maxDurationInSec: number): void;
}>();

const iconAudio = resolveComponent("DashboardIconAudio");
const iconVideo = resolveComponent("DashboardIconVideo");
const iconStreamed = resolveComponent("DashboardIconStreamed");

const iconComponent = computed(() => {
  switch (props.room_type) {
    case "audio":
      return iconAudio;
    case "video":
      return iconVideo;
    case "streamed":
      return iconStreamed;
  }
});
const currentParticipants = computed(() => {
  if (props.session) {
    return props.session.participants_count;
  }
  return 0;
});

const roomLabel = computed(() => {
  if (props.max_participants === 10) {
    return { text: "Free", class: "primary" } as const;
  }
  return null;
});

const joinText = computed(() => {
  return currentParticipants.value > 0
    ? "Join meeting"
    : "Start instant meeting";
});

const handleClose = () => {
  emit("close");
};

const handleScheduleClick = () => {
  emit("schedule-click", props.id, props.max_duration_in_sec);
};

const handleJoinClick = () => {
  const roomUrl = `${window.origin}/room/${props.private_url}`;
  return navigateTo(roomUrl, { external: true });
};
</script>
<style scoped lang="scss">
.EnterRoom {
  &__heading-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__name {
    @include header-xs-bold;
    color: $color-element-dark-strong;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    margin-left: 32px;
  }
  &__participants {
    @include label-m;
    color: $color-element-dark-disabled;
    margin-right: 6px;
  }
  &__label {
    @include label-m;
    color: $color-element-dark-disabled;
    padding-left: 6px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 10px;
      background: $color-element-dark-disabled;
    }
    &.primary {
      color: $color-primary;
    }
  }
  &__wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
  }
  &__button {
    @include button-primary;
    &.secondary {
      color: $color-primary;
      background: none;
    }
  }
}
</style>
