<template>
  <div>
    <div class="Start__overlay"></div>
    <div class="Start__wrapper">
      <div class="Start__content">
        <div class="Start__illustration-wrapper">
          <IllustrationOnboardingStart />
        </div>
        <button class="Start__button-skip" @click="handleSkip">Skip</button>
        <h3 class="Start__title">
          {{
            betaTesterStatus.isBetaTester
              ? "KingsConference Now Released"
              : "Welcome to brand new KingsConference!"
          }}
        </h3>
        <p v-if="betaTesterStatus.isBetaTester" class="Start__description">
          We're happy to announce that the testing period has ended, and you are
          now using version 1.0! Take a&nbsp;quick guide through the app
          or&nbsp;skip&nbsp;it&nbsp;to&nbsp;start using KingsConference right
          away.
        </p>
        <p v-else class="Start__description">
          We're happy to announce that we've created a new version of&nbsp;
          KingsConference. Now you can schedule and manage meetings easily like
          never before!
        </p>
        <button class="Start__button" @click="handleStartOnboarding">
          Guide me
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useBetaTesterStatusStore } from "~/store/betaTesterStatus";

const betaTesterStatus = useBetaTesterStatusStore();

const emit = defineEmits<{
  (e: "skip"): void;
  (e: "startOnboarding"): void;
}>();

const handleSkip = () => {
  emit("skip");
};

const handleStartOnboarding = () => {
  emit("startOnboarding");
};
</script>
<style scoped lang="scss">
.Start {
  &__wrapper {
    width: 100%;
    background: $brand-primary-600;
    border-radius: 20px 20px 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 340px;
    z-index: 3;
    @media (min-height: 520px) {
      max-height: 490px;
    }
    @include md {
      max-width: 414px;
      border-radius: 20px;
      bottom: unset;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @include custom-min-width(1163px) {
      left: calc(50% + 170px);
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-basic-overlay;
    z-index: 2;
  }
  &__illustration-wrapper {
    width: 162px;
    display: none;
    @media (min-height: 520px) {
      display: flex;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    padding: 24px 20px 20px 20px;
  }
  &__button-skip {
    @include LabelLarge;
    position: absolute;
    right: 2px;
    top: 14px;
    padding: 10px 18px;
    border: none;
    background: none;
    color: $color-element-light-strong;
    cursor: pointer;
  }
  &__title {
    @include TitleLarge;
    color: $color-element-light-strong;
    padding-top: 20px;
    padding-bottom: 12px;
    @include sm {
      @include HeadlineSmall;
    }
  }
  &__description {
    @include BodyLarge;
    color: $color-element-light-strong;
    padding-bottom: 20px;
  }
  &__button {
    @include button-primary;
    background: $color-element-light-strong;
    color: $brand-primary-500;
    border: none;
  }
}
</style>
