<template>
  <o-checkbox
    v-model="modelValue"
    :true-value="trueValue"
    @update:model-value="emitUpdateModelValue"
    >{{ label }}</o-checkbox
  >
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
export type Value = boolean | number | string;

type Props = {
  label?: string;
  initialValue?: Value;
  valueWhenTrue?: Value;
};
const props = defineProps<Props>();
const trueValue = computed(() => {
  return props.valueWhenTrue || true;
});
const modelValue = ref(props.initialValue);
const emit = defineEmits(["update:modelValue"]);
const emitUpdateModelValue = (value: Value) => {
  emit("update:modelValue", value);
};
defineExpose({ value: modelValue });
</script>
