<template>
  <div class="OfferSelection__container">
    <DashboardRoomOfferTitle
      :room-type="offers[0].room_type"
      :name="offers[0].name"
      :expired="false"
    />
    <div class="OfferSelection__filters">
      <DashboardAddRoomFilter
        :selected="subscriptionType"
        :wrapper-class="offers[0].room_type"
        :annual-discount="annualDiscount"
        @select="handleFilterChange"
      />
      <DashboardAddRoomCurrencySelection />
    </div>
    <div class="OfferSelection__offers">
      <DashboardAddRoomOffer
        v-for="offer in offersFiltered"
        :id="offer.offers[0].id"
        :key="offer.offer_type_id"
        :name="offer.name"
        :price="offer.offers[0].price"
        :currency="offer.offers[0].currency"
        :max-participants="offer.max_participants"
        :max-duration-in-hours="offer.max_duration_in_sec / 3600"
        :room-type="offer.room_type"
        :selected-id="selectedOfferId"
        @choose-offer="handleChooseOffer"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCurrencyStore } from "~/store/currency";
import { useDashboardStore } from "~/store/dashboard";
import { OfferType } from "~/types";

const props = defineProps<{
  offers: OfferType[];
}>();

const dashboardStore = useDashboardStore();
const currencyStore = useCurrencyStore();

const selectedOfferId = computed(() => {
  return `${dashboardStore.searchParamsState.offerId}`;
});

const subscriptionType = computed(() => {
  const type = `${dashboardStore.searchParamsState.subscriptionType}`;
  if (type === "monthly" || type === "annual") {
    return type;
  }

  return "monthly";
});

const annualDiscount = computed(() => {
  return props.offers.reduce((acc, curr) => {
    const discount = curr.offers
      .filter((o) => o.subscription_type === "annual")
      .reduce((a, c) => {
        if (a > c.percentage_discount) {
          return a;
        }
        return c.percentage_discount;
      }, 0);
    if (discount > acc) {
      return discount;
    }
    return acc;
  }, 0);
});

const offersFiltered = computed(() => {
  if (!props.offers.length) {
    return [];
  }

  return props.offers
    .map((offer) => {
      const offersBySelectedCurrencyAndPeriod = offer.offers.filter((o) => {
        const currencyFilter =
          o.currency === currencyStore.defaultCurrency.currency.code;
        const subscriptionTypeFilter =
          o.subscription_type === subscriptionType.value;

        return currencyFilter && subscriptionTypeFilter;
      });

      return {
        ...offer,
        offers: offersBySelectedCurrencyAndPeriod,
      };
    })
    .sort((a, b) => a.max_participants - b.max_participants);
});

const handleFilterChange = (value: "monthly" | "annual") => {
  dashboardStore.handleClearRoomOffer();
  dashboardStore.handleAddRoomChooseSubscriptionType(value);
};

const handleChooseOffer = (id: string) => {
  dashboardStore.handleChooseRoomOffer(id);
};
</script>
<style scoped lang="scss">
.OfferSelection {
  &__filters {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 12px;
    align-items: center;
    justify-content: center;
    @include md {
      justify-content: space-between;
      flex-direction: row;
    }
  }
  &__offers {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-top: 16px;
  }
}
</style>
