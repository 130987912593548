<template>
  <CommonDetailsWrapper @close="handleClose" @go-back="handleGoBack">
    <template #header>
      <div class="CalendarEventDetails__header-wrapper">
        Meeting details
        <button
          class="CalendarEventDetails__edit-icon"
          @click="handleEditClick"
        >
          <o-icon icon="pencil" />
        </button>
      </div>
    </template>
    <template #content>
      <div
        v-if="calendarEvent.data.value"
        class="CalendarEventDetails__wrapper"
      >
        <h3 class="CalendarEventDetails__title">
          {{ calendarEvent.data.value.meeting.name }}
        </h3>
        <div class="CalendarEventDetails__room-wrapper">
          <component :is="iconComponent" v-bind="{ expired: false }" />
          {{ calendarEvent.data.value.meeting.room_name }}
        </div>
        <div class="CalendarEventDetails__share-wrapper">
          <FormLinkShare :label="'Share room link'" :url="roomUrl" />
        </div>
        <div class="CalendarEventDetails__details">
          <div class="CalendarEventDetails__detail-row">
            <span class="CalendarEventDetails__detail-title">Date</span>
            <span class="CalendarEventDetails__detail-text">{{
              dateText
            }}</span>
          </div>
          <div class="CalendarEventDetails__detail-row">
            <span class="CalendarEventDetails__detail-title">Duration</span>
            <span class="CalendarEventDetails__detail-text">{{
              durationText
            }}</span>
          </div>
          <div class="CalendarEventDetails__detail-row">
            <span class="CalendarEventDetails__detail-title">Invitees</span>
            <span class="CalendarEventDetails__detail-text"
              ><CommonAvatarGroup
                :participants-count="calendarEvent.data.value.invitees_count"
                :users-profiles="usersProfiles"
            /></span>
          </div>
        </div>
        <div class="CalendarEventDetails__buttons">
          <button
            v-if="isOwner"
            class="CalendarEventDetails__button"
            @click="handleJoinClick"
          >
            Join meeting now
          </button>
          <button
            v-if="isOwner"
            class="CalendarEventDetails__button edit"
            @click="handleEditClick"
          >
            Edit meeting
          </button>
          <button
            class="CalendarEventDetails__button"
            @click="handleParticipantsListClick"
          >
            Show list of all participants
          </button>
          <button
            v-if="isOwner"
            class="CalendarEventDetails__button"
            @click="handleInviteClick"
          >
            Invite users from KingsChat
          </button>
          <button
            v-if="isOwner"
            class="CalendarEventDetails__button delete"
            @click="handleDeleteClick"
          >
            Delete meeting
          </button>
          <button
            v-if="!isOwner"
            class="CalendarEventDetails__button delete"
            @click="handleRemoveFromScheduleClick"
          >
            Remove from schedule
          </button>
        </div>
      </div>
    </template>
  </CommonDetailsWrapper>
</template>
<script setup lang="ts">
import { DateTime, Interval } from "luxon";
import { CalendarEvent, Meeting, MeetingRepeatability, Profile } from "~/types";
const runtimeConfig = useRuntimeConfig();

type Props = {
  id: string;
  userId: string;
};

// TODO move to types
type CalendarEventDetails = {
  calendar_event: CalendarEvent;
  invitees_count: number;
  invitees_ids: string[];
  meeting: Meeting;
  users_profiles: Profile[];
};

const emit = defineEmits<{
  (e: "close"): void;
  (e: "go-back"): void;
  (
    e: "edit-click",
    props: {
      meetingId: string;
      name: string;
      roomId: string;
      repeatability: MeetingRepeatability;
      startDatetime: string;
      endDatetime: string;
    },
  ): void;
  (e: "participants-list-click", id: string): void;
  (e: "invite-click", id: string): void;
  (
    e: "delete-click",
    meetingId: string,
    calendarEventId: string,
    enableOwnerDelete: boolean,
  ): void;
  (
    e: "remove-from-schedule-click",
    meetingId: string,
    calendarEventId: string,
    enableOwnerDelete: boolean,
  ): void;
}>();

const props = defineProps<Props>();

const calendarEvent = await useAuthRequest<CalendarEventDetails>(
  `${runtimeConfig.public.backendApiUrl}/meetings/calendar_events/${props.id}`,
  {},
  { immediate: false },
);

const isOwner = computed(() => {
  return props.userId === calendarEvent.data.value.meeting.owner_id;
});

const handleEditClick = () => {
  emit("edit-click", {
    meetingId: calendarEvent.data.value.meeting.id,
    name: calendarEvent.data.value.meeting.name,
    roomId: calendarEvent.data.value.meeting.room_id,
    repeatability: calendarEvent.data.value.meeting.repeatability,
    startDatetime: calendarEvent.data.value.calendar_event.start_datetime,
    endDatetime: calendarEvent.data.value.calendar_event.end_datetime,
  });
};

const handleParticipantsListClick = () => {
  emit("participants-list-click", calendarEvent.data.value.meeting.id);
};

const handleInviteClick = () => {
  emit("invite-click", calendarEvent.data.value.meeting.id);
};

const handleDeleteClick = () => {
  emit(
    "delete-click",
    calendarEvent.data.value.calendar_event.meeting_id,
    calendarEvent.data.value.calendar_event.id,
    isOwner.value,
  );
};

const handleRemoveFromScheduleClick = () => {
  emit(
    "remove-from-schedule-click",
    calendarEvent.data.value.calendar_event.meeting_id,
    calendarEvent.data.value.calendar_event.id,
    isOwner.value,
  );
};

const handleClose = () => {
  emit("close");
};

const handleGoBack = () => {
  emit("go-back");
};

const handleJoinClick = () => {
  navigateTo(roomUrl.value, { external: true });
};

const iconAudio = resolveComponent("DashboardIconAudio");
const iconVideo = resolveComponent("DashboardIconVideo");
const iconStreamed = resolveComponent("DashboardIconStreamed");

const iconComponent = computed(() => {
  switch (calendarEvent.data.value.meeting.room_type) {
    case "audio":
      return iconAudio;
    case "video":
      return iconVideo;
    case "streamed":
      return iconStreamed;
  }
});

const meetingTime = computed(() => {
  const zoneName = DateTime.local().zoneName;
  const start = DateTime.fromISO(
    calendarEvent.data.value.calendar_event.start_datetime,
    {
      zone: "utc",
    },
  ).toLocal();
  const end = DateTime.fromISO(
    calendarEvent.data.value.calendar_event.end_datetime,
    { zone: "utc" },
  ).toLocal();

  const localeOptions = {
    locale: DateTime.local().locale || "en-GB",
  };
  if (zoneName === "Africa/Lagos") {
    localeOptions.locale = "en-US";
  }

  return {
    start: `${start.toLocaleString(DateTime.TIME_SIMPLE, localeOptions)}`,
    end: `${end.toLocaleString(DateTime.TIME_SIMPLE, localeOptions)}`,
  };
});

const dateText = computed(() => {
  const relativeText = DateTime.fromISO(
    calendarEvent.data.value.calendar_event.start_datetime,
    { zone: "utc" },
  )
    .toLocal()
    .setLocale("en-US")
    .toRelativeCalendar();

  return `${relativeText} at ${meetingTime.value.start}`;
});

const durationText = computed(() => {
  const start = DateTime.fromISO(
    calendarEvent.data.value.calendar_event.start_datetime,
    { zone: "utc" },
  ).toLocal();
  const end = DateTime.fromISO(
    calendarEvent.data.value.calendar_event.end_datetime,
    { zone: "utc" },
  ).toLocal();

  const diff = Interval.fromDateTimes(start, end);

  if (diff.length("minutes") < 60) {
    return `${diff.toDuration("minutes").toHuman()} (ends at ${
      meetingTime.value.end
    })`;
  }
  if (diff.length("minutes") % 60 === 0) {
    return `${diff.toDuration("hours").toHuman()} (ends at ${
      meetingTime.value.end
    })`;
  }

  return `${diff.toDuration(["hours", "minutes"]).toHuman()} (ends at ${
    meetingTime.value.end
  })`;
});

const roomUrl = computed(() => {
  const privateUrl = calendarEvent.data.value.meeting.room_private_url;
  return `${window.origin}/room/${privateUrl}`;
});

const usersProfiles = computed(() => {
  return calendarEvent.data.value.users_profiles.slice(0, 3);
});

onMounted(() => {
  calendarEvent.execute();
});
</script>
<style scoped lang="scss">
.CalendarEventDetails {
  &__header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__edit-icon {
    position: absolute;
    right: 0;
    color: $color-element-dark-strong;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    @include md {
      display: none;
    }
  }
  &__title {
    @include header-m;
    color: $color-element-dark-strong;
    margin-bottom: 8px;
    margin-top: 20px;
    @include md {
      margin-top: 0;
    }
  }
  &__room-wrapper {
    @include label-xl;
    color: $color-element-dark-strong;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &__share-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    background: $color-neutral-2;
    border-radius: 12px;
    margin-top: 20px;
  }
  &__details {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    row-gap: 16px;
  }
  &__detail-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__detail-title {
    @include label-xl-bold;
  }
  &__detail-text {
    @include body-m;
    color: $color-element-dark-medium;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    row-gap: 8px;
  }
  &__button {
    @include label-xl;
    color: $color-primary;
    border: none;
    background: none;
    padding: 8px 18px;
    cursor: pointer;
    &.edit {
      display: none;
      @include md {
        display: initial;
      }
    }
    &.delete {
      color: $color-basic-error;
    }
  }
}
</style>
