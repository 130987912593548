<template>
  <CommonBottomDrawer @close="handleClose">
    <template #header
      ><div class="CalendarEventMore__header-wrapper">
        <span>{{ name }}</span>
        <div class="CalendarEventMore__info">
          <DashboardIconClock />
          <span class="CalendarEventMore__time">{{ timeText }}</span>
          <component :is="iconComponent" />
          <span
            :class="roomTypeClassName"
            class="CalendarEventMore__room-type"
            >{{ roomName }}</span
          >
        </div>
      </div></template
    >
    <template #content>
      <ul class="CalendarEventMore__wrapper">
        <li>
          <button class="CalendarEventMore__button" @click="handleDetailsClick">
            <o-icon icon="information" /> See details
          </button>
        </li>
        <li>
          <button
            class="CalendarEventMore__button"
            @click="handleCopyLinkClick"
          >
            <o-icon icon="link" /> Copy link
          </button>
        </li>
        <li v-if="enableEdit">
          <button class="CalendarEventMore__button" @click="handleEditClick">
            <o-icon icon="pencil" /> Edit
          </button>
        </li>
        <li>
          <button
            v-if="enableEdit"
            class="CalendarEventMore__button delete"
            @click="handleDeleteClick"
          >
            <o-icon icon="delete" /> Delete
          </button>
          <button
            v-if="!enableEdit"
            class="CalendarEventMore__button delete"
            @click="handleRemoveFromScheduleClick"
          >
            <o-icon icon="delete" /> Remove from schedule
          </button>
        </li>
      </ul>
    </template>
  </CommonBottomDrawer>
</template>
<script setup lang="ts">
import { DateTime } from "luxon";
import { MeetingRepeatability, RoomType } from "~/types";
type Props = {
  id: string;
  meetingId: string;
  roomId: string;
  repeatability: MeetingRepeatability;
  name: string;
  startDateTime: string;
  endDateTime: string;
  roomPrivateUrl: string;
  roomType: RoomType;
  roomName: string;
  enableEdit: boolean;
  owner: {
    id: string;
    username: string;
    name: string;
    avatarUrl?: string | null;
  };
};
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "close"): void;
  (e: "details-click", id: string): void;
  (e: "copy-link-click"): void;
  (
    e: "edit-click",
    props: {
      meetingId: string;
      name: string;
      roomId: string;
      repeatability: MeetingRepeatability;
      startDatetime: string;
      endDatetime: string;
    },
  ): void;
  (
    e: "delete-click",
    meetingId: string,
    calendarEventId: string,
    enableOwnerDelete: boolean,
  ): void;
  (
    e: "remove-from-schedule-click",
    meetingId: string,
    calendarEventId: string,
    enableOwnerDelete: boolean,
  ): void;
}>();

const handleClose = () => {
  emit("close");
};

const handleDetailsClick = () => {
  emit("details-click", props.id);
};

const handleCopyLinkClick = async () => {
  emit("copy-link-click");
  const url = `${window.location.origin}/room/${props.roomPrivateUrl}`;
  await navigator.clipboard.writeText(url);
};

const handleEditClick = () => {
  emit("edit-click", {
    meetingId: props.meetingId,
    name: props.name,
    startDatetime: props.startDateTime,
    endDatetime: props.endDateTime,
    roomId: props.roomId,
    repeatability: props.repeatability,
  });
};

const handleDeleteClick = () => {
  emit("delete-click", props.meetingId, props.id, props.enableEdit);
};

const handleRemoveFromScheduleClick = () => {
  emit(
    "remove-from-schedule-click",
    props.meetingId,
    props.id,
    props.enableEdit,
  );
};

const iconVideo = resolveComponent("DashboardIconCalendarEventVideo");
const iconAudio = resolveComponent("DashboardIconCalendarEventAudio");
const iconStreamed = resolveComponent("DashboardIconCalendarEventStreamed");

const iconComponent = computed(() => {
  switch (props.roomType) {
    case "video":
      return iconVideo;
    case "audio":
      return iconAudio;
    case "streamed":
      return iconStreamed;
  }
});

const roomTypeClassName = computed(() => {
  switch (props.roomType) {
    case "video":
      return "primary";
    case "audio":
      return "tertiary";
    case "streamed":
      return "secondary";
  }
});

const timeText = computed(() => {
  const zoneName = DateTime.local().zoneName;
  const start = DateTime.fromISO(props.startDateTime, {
    zone: "utc",
  }).toLocal();
  const end = DateTime.fromISO(props.endDateTime, { zone: "utc" }).toLocal();
  const localeOptions = {
    locale: DateTime.local().locale || "en-GB",
  };
  if (zoneName === "Africa/Lagos") {
    localeOptions.locale = "en-US";
  }
  return `${start.toLocaleString(
    DateTime.TIME_SIMPLE,
    localeOptions,
  )} - ${end.toLocaleString(DateTime.TIME_SIMPLE, localeOptions)}`;
});
</script>
<style scoped lang="scss">
.CalendarEventMore {
  &__header-wrapper {
    margin-bottom: 4px;
  }
  &__wrapper {
    padding: 0;
    margin: 0;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    row-gap: 32px;
  }
  &__button {
    @include body-l;
    color: $color-element-dark-strong;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    background: none;
    border: none;
    cursor: pointer;
    .o-icon {
      color: $color-element-dark-disabled;
    }
    &.delete {
      color: $color-basic-error;
      .o-icon {
        color: $color-basic-error;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
  }
  &__time {
    @include label-m;
    color: $color-element-dark-basic;
    margin-left: 4px;
    padding-right: 18px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 8px;
      width: 2px;
      height: 100%;
      background: $color-element-dark-weak;
      border-radius: 100px;
    }
  }
  &__room-type {
    @include label-m;
    margin-left: 4px;
    &.primary {
      color: $color-primary;
    }
    &.secondary {
      color: $color-secondary;
    }
    &.tertiary {
      color: $color-tertiary;
    }
  }
}
</style>
