<template>
  <CommonSidebarWrapper
    wrapper-class="settings"
    :container-class="containerClass"
    @close="handleClose"
    @go-back="handleGoBack"
  >
    <template #header>Settings</template>
    <template #content>
      <NuxtLink to="/dashboard" class="Settings__logo">
        <IconKingsConference />
      </NuxtLink>
      <div class="Settings__schedule-wrapper">
        <DashboardActionButtonFloatingSchedule
          @click="handleScheduleMeetingClick"
        />
      </div>
      <ul class="Settings__navigation">
        <li class="Settings__list-element">
          <NuxtLink
            class="Settings__navigation-link Settings__link-hover"
            to="/dashboard"
            exact-active-class="active"
            @click="handleClose"
          >
            <o-icon icon="home" custom-size="mdi-24px" />
            Home
          </NuxtLink>
        </li>
        <li class="Settings__list-element">
          <NuxtLink
            to="/dashboard/device-preferences"
            class="Settings__navigation-link Settings__link-hover"
            exact-active-class="active"
            @click="handleClose"
          >
            <DashboardIconDevicePreferences />
            Device preferences
          </NuxtLink>
        </li>
      </ul>
      <ul class="Settings__links">
        <li class="Settings__list-element">
          <button
            class="Settings__link Settings__link-hover"
            href=""
            @click="handleOpenSupport"
          >
            <DashboardIconContactSupport />
            Contact support
          </button>
        </li>
        <li class="Settings__list-element">
          <button
            class="Settings__link Settings__link-hover Settings__onboarding-button"
            @click="handleRestartOnboarding"
          >
            <o-icon icon="book" custom-size="mdi-24px" />
            Guide me
          </button>
        </li>
        <li class="Settings__list-element">
          <NuxtLink
            class="Settings__link Settings__link-hover"
            to="/"
            target="_blank"
          >
            <DashboardIconAbout />
            About
            <o-icon custom-size="mdi-20px" icon="open-in-new" />
          </NuxtLink>
        </li>
        <li class="Settings__list-element">
          <NuxtLink
            class="Settings__link Settings__link-hover"
            to="/privacy-policy"
            target="_blank"
          >
            <DashboardIconPrivacyPolicy />
            Privacy policy
            <o-icon custom-size="mdi-20px" icon="open-in-new" />
          </NuxtLink>
        </li>
      </ul>
      <div class="Settings__profile-wrapper">
        <DashboardSettingsProfile
          :username="username"
          :name="name"
          :avatar-url="avatarUrl"
        />
      </div>
    </template>
  </CommonSidebarWrapper>
</template>
<script setup lang="ts">
import { useSupportStore } from "~/store/support";
import { useDashboardStore } from "~/store/dashboard";
import { useDashboardOnboardingStore } from "~/store/dashboardOnboarding";
import { useRoomOnboardingStore } from "~/store/roomOnboarding";

type Props = {
  username: string;
  name: string;
  avatarUrl: string | null;
};

defineProps<Props>();

const dashboardStore = useDashboardStore();
const dashboardOnboardingStore = useDashboardOnboardingStore();
const roomOnboardingStore = useRoomOnboardingStore();

const handleRestartOnboarding = () => {
  dashboardOnboardingStore.restartOnboarding();
  roomOnboardingStore.restartOnboarding();
};

const supportStore = useSupportStore();

const containerClass = computed(() => {
  return dashboardStore.searchParamsState.settings === "open" ? "open" : "";
});

const emit = defineEmits<{
  (e: "close"): void;
  (e: "go-back"): void;
  (e: "log-out"): void;
  (e: "schedule-meeting"): void;
}>();

const handleClose = () => {
  emit("close");
};

const handleGoBack = () => {
  emit("go-back");
};

const handleScheduleMeetingClick = () => {
  emit("schedule-meeting");
};

const handleOpenSupport = () => {
  supportStore.openSupport();
};
</script>
<style scoped lang="scss">
.Settings {
  &__logo {
    display: none;
    @include custom-min-width(1163px) {
      display: initial;
    }
  }
  &__profile-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
  }
  &__schedule-wrapper {
    display: none;
    padding: 60px 0;
    button {
      box-shadow: none;
    }
    @include custom-min-width(1163px) {
      display: initial;
    }
  }
  &__navigation,
  &__links {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin: 0;
    padding: 0;
  }
  &__navigation {
    padding-bottom: 32px;
  }
  &__links {
    padding-top: 32px;
    border-top: 1px solid $color-neutral-200;
  }
  &__list-element {
    position: relative;
  }
  &__navigation-link {
    @include TitleSmall;
    text-decoration: none;
    background: none;
    border: none;
    color: $color-element-dark-strong;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;
    svg {
      margin-right: 9px;
    }
    .o-icon {
      color: #a6a9ab;
      margin-right: 9px;
    }
  }
  &__link {
    @include LabelMedium;
    text-decoration: none;
    background: none;
    border: none;
    color: $color-element-dark-strong;
    display: flex;
    padding: 0;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;
    svg {
      margin-right: 8px;
    }
    .o-icon {
      color: $color-element-dark-weak;
      margin-left: auto;
    }
  }
  &__link-hover {
    &.active {
      color: $brand-primary-500;
      &::after {
        content: "";
        position: absolute;
        left: -24px;
        height: 20px;
        width: 3px;
        border-radius: 5px;
        background: $brand-primary-500;
      }
      @include custom-min-width(1163px) {
        &::after {
          left: -16px;
        }
      }
      .o-icon {
        color: $brand-primary-500;
      }
      svg {
        fill: currentColor;
      }
    }
    &:not(.active):hover {
      &::after {
        content: "";
        position: absolute;
        left: -24px;
        height: 20px;
        width: 3px;
        border-radius: 5px;
        background: $color-element-dark-weak;
      }
      @include custom-min-width(1163px) {
        &::after {
          left: -16px;
        }
      }
    }
  }
  &__onboarding-button {
    .o-icon {
      margin-left: 0;
      margin-right: 8px;
      color: $color-element-dark-disabled;
    }
  }
}
</style>
