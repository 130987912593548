<template>
  <CommonDetailsWrapper
    ref="wrapper"
    @close="handleClose"
    @go-back="handleClose"
  >
    <template #header>Participants</template>
    <template #content>
      <div class="CalendarEventParticipants__wrapper">
        <ul
          v-if="participantsState.data && participantsState.data.length > 0"
          class="CalendarEventParticipants__list"
        >
          <li
            v-for="participant in participantsState.data"
            :key="participant.id"
            class="CalendarEventParticipants__list-element"
          >
            <DashboardListParticipant v-bind="participant" />
          </li>
        </ul>
      </div>
    </template>
  </CommonDetailsWrapper>
</template>
<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { Profile } from "~/types";
const wrapper = ref<{ containerRef: HTMLElement | null }>({
  containerRef: null,
});
const runtimeConfig = useRuntimeConfig();

type Props = {
  meetingId: string;
};

type ParticipantsState = {
  status: "LOADED" | "LOADING" | "INITIAL";
  nextToken: string;
  data: Profile[];
};

const props = defineProps<Props>();

const participantsState = ref<ParticipantsState>({
  status: "INITIAL",
  nextToken: "",
  data: [],
});

const emit = defineEmits<{
  (e: "close"): void;
}>();

const handleClose = () => {
  emit("close");
};

const calendarEventsUrl = computed(() => {
  const url = new URL(
    `${runtimeConfig.public.backendApiUrl}/meetings/${props.meetingId}/participants`,
  );
  url.searchParams.set("next_token", participantsState.value.nextToken);

  return url;
});

const fetchParticipants = async () => {
  if (participantsState.value.status === "LOADING") {
    return;
  }
  participantsState.value.status = "LOADING";
  return await useAuthRequest<{
    users_profiles: Profile[];
    next_token: string;
  }>(calendarEventsUrl.value.href)
    .then((res) => {
      if (res.data.value.users_profiles.length > 0) {
        participantsState.value.data.push(...res.data.value.users_profiles);
      }
      participantsState.value.nextToken = res.data.value.next_token;
      participantsState.value.status = "LOADED";
    })
    .catch((err) => {
      Sentry.captureException(err);
      participantsState.value.status = "LOADED";
    });
};

useInfiniteScroll(
  () => wrapper.value?.containerRef || null,
  async () => {
    await fetchParticipants();
  },
  {
    distance: 100,
    canLoadMore: () => {
      if (
        participantsState.value.status === "LOADED" &&
        participantsState.value.nextToken === ""
      ) {
        return false;
      }
      return true;
    },
  },
);
</script>
<style scoped lang="scss">
.CalendarEventParticipants {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__list {
    list-style: none;
    margin: 24px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
}
</style>
