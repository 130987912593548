<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#A6A9AB"
    xmlns="http://www.w3.org/2000/svg"
    class="DevicePreferences__wrapper"
  >
    <path
      d="M18.0609 13.6326C17.5583 13.6326 17.1377 14.0018 17.0557 14.5044C16.6351 16.9149 14.5324 18.7612 11.9988 18.7612C9.46531 18.7612 7.36258 16.9149 6.94204 14.5044C6.85998 14.0018 6.43943 13.6326 5.93683 13.6326C5.31114 13.6326 4.81879 14.1865 4.91111 14.8019C5.41371 17.8791 7.87544 20.2895 10.9731 20.7306C11.5694 20.8155 12.1348 20.8573 13.0246 20.7306C16.1223 20.2895 18.584 17.8791 19.0866 14.8019C19.1892 14.1865 18.6866 13.6326 18.0609 13.6326Z"
      fill="#A6A9AB"
    />
    <path
      d="M15.7427 13.1624C15.7427 15.2341 14.0705 16.9063 11.9988 16.9063C9.92722 16.9063 8.25495 15.2341 8.25495 13.1624V6.92261C8.25495 4.85099 9.92722 3.17871 11.9988 3.17871C14.0705 3.17871 15.7427 4.85099 15.7427 6.92261V13.1624Z"
      fill="#A6A9AB"
    />
  </svg>
</template>
<style scoped lang="scss">
.DevicePreferences {
  &__wrapper {
    path {
      fill: inherit;
    }
  }
}
</style>