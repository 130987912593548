<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#7EA6F7" />
    <path
      d="M10.0833 7.375V5.91667C10.0833 5.6875 9.89583 5.5 9.66667 5.5H4.66667C4.4375 5.5 4.25 5.6875 4.25 5.91667V10.0833C4.25 10.3125 4.4375 10.5 4.66667 10.5H9.66667C9.89583 10.5 10.0833 10.3125 10.0833 10.0833V8.625L11.0375 9.57917C11.3 9.84167 11.75 9.65417 11.75 9.28333V6.7125C11.75 6.34167 11.3 6.15417 11.0375 6.41667L10.0833 7.375Z"
      fill="white"
    />
  </svg>
</template>
