<template>
  <div class="FormInput__container">
    <o-field class="FormInput__wrapper" :variant="variant">
      <template #label>{{ label }} </template>
      <o-datepicker
        v-model="value"
        :first-day-of-week="1"
        :day-names="dayNames"
        icon-right="calendar-month"
        icon-next="arrow-right"
        icon-prev="arrow-left"
        :nearby-selectable-month-days="true"
        :min-date="minDate"
        locale="en-GB"
        trap-focus
        :mobile-modal="false"
        :mobile-native="false"
        :name="name"
        @update:model-value="emitUpdateModelValue"
        @focus.once="handleFocus(true)"
        @blur.once="handleFocus(false)"
      >
      </o-datepicker>
    </o-field>
    <span class="FormInput__error">
      {{ !focused && !meta.valid ? errorMessage : "" }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { DateTime } from "luxon";
import { ref } from "vue";
import { useField } from "vee-validate";
type Props = {
  name: string;
  label: string;
  initialValue?: Date;
};
const props = defineProps<Props>();

const emit = defineEmits(["update:modelValue"]);
const emitUpdateModelValue = (value: Date) => {
  emit("update:modelValue", value);
};

const focused = ref(false);
const { value, errorMessage, meta } = useField(() => props.name, undefined, {
  initialValue: props.initialValue,
});

const handleFocus = (value: boolean) => {
  focused.value = value;
};

const variant = computed(() => {
  if (meta.dirty && !focused.value && !meta.valid) {
    return "danger";
  }
  if (meta.dirty && meta.valid) {
    return "success";
  }
  return "primary";
});

const minDate = ref(DateTime.now().startOf("day").toJSDate());
const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
defineExpose({ value });
</script>
<style scoped lang="scss">
.FormInput {
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__error {
    @include body-s;
    color: $color-basic-error;
    margin: 4px 0;
    &.light {
      color: $color-basic-error-light;
    }
  }
}
</style>
