<template>
  <CommonDetailsWrapper
    ref="wrapper"
    class="AllCalendarEvents__container"
    @close="handleClose"
    @go-back="handleGoBack"
  >
    <template #header> All meetings </template>
    <template #content>
      <div class="AllCalendarEvents__wrapper">
        <div class="AllCalendarEvents__filters">
          <button
            class="AllCalendarEvents__filter"
            :class="{
              active:
                dashboardStore.searchParamsState.allMeetingsFilter !== 'owned',
            }"
            @click="dashboardStore.setAllCalendarEventsFilter('')"
          >
            All
          </button>
          <button
            class="AllCalendarEvents__filter"
            :class="{
              active:
                dashboardStore.searchParamsState.allMeetingsFilter === 'owned',
            }"
            @click="dashboardStore.setAllCalendarEventsFilter('owned')"
          >
            Hosted by you
          </button>
        </div>
        <div
          v-for="day in dashboardStore.allCalendarEvents.grouped"
          :key="day[0]"
          class="AllCalendarEvents__groups"
        >
          <span class="AllCalendarEvents__group-title">
            {{ day[0] }}
          </span>
          <ul class="AllCalendarEvents__meetings-list">
            <li
              v-for="item in day[1]"
              :key="item.id"
              class="AllCalendarEvents__meetings-list-item"
            >
              <DashboardCalendarEventTile
                v-bind="item"
                @more-click="handleMoreClick"
                @tile-click="handleTileClick"
              />
            </li>
          </ul>
        </div>
        <DashboardAllCalendarEventsEmpty
          v-if="isEmpty"
          variant="NORMAL"
          @schedule-click="handleScheduleClick"
        />
      </div>
    </template>
  </CommonDetailsWrapper>
</template>
<script setup lang="ts">
import { useInfiniteScroll } from "@vueuse/core";
import { useDashboardStore } from "@/store/dashboard";

const wrapper = ref<{ containerRef: HTMLElement | null }>({
  containerRef: null,
});

const emit = defineEmits<{
  (e: "close"): void;
  (e: "go-back"): void;
  (e: "more-click", id: string, type: "ALL"): void;
  (e: "tile-click", id: string): void;
  (e: "schedule-click"): void;
}>();

const handleClose = () => {
  emit("close");
};

const handleGoBack = () => {
  emit("go-back");
};

const handleMoreClick = (id: string) => {
  emit("more-click", id, "ALL");
};

const handleTileClick = (id: string) => {
  emit("tile-click", id);
};

const handleScheduleClick = () => {
  emit("schedule-click");
};

const dashboardStore = useDashboardStore();

const isEmpty = computed(() => {
  return (
    dashboardStore.allCalendarEvents.grouped.size === 0 &&
    dashboardStore.allCalendarEvents.nextToken === "" &&
    dashboardStore.allCalendarEvents.status === "LOADED"
  );
});

useInfiniteScroll(
  () => wrapper.value.containerRef,
  async () => {
    await dashboardStore.fetchCalendarEvents();
  },
  {
    distance: 100,
    canLoadMore: () => {
      if (
        dashboardStore.allCalendarEvents.status === "LOADED" &&
        dashboardStore.allCalendarEvents.nextToken === ""
      ) {
        return false;
      }
      return true;
    },
  },
);
</script>
<style scoped lang="scss">
.AllCalendarEvents {
  &__container {
    @include md {
      display: none;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__filters {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  &__filter {
    @include label-l;
    color: $color-element-dark-strong;
    padding: 7px 14px;
    cursor: pointer;
    border: 1px solid $color-neutral-700;
    border-radius: 8px;
    background: none;
    &.active {
      border: none;
      background: $brand-primary-100;
    }
  }
  &__groups {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 20px;
  }
  &__group-title {
    @include header-s;
    padding: 8px 0;
    color: $color-element-dark-strong;
  }
  &__meetings-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  &__meetings-list-item {
    padding: 0;
  }
}
</style>
