<template>
  <CommonBottomDrawer @close="handleClose">
    <template #header>
      <div class="RoomSettings__header-wrapper">
        <span class="RoomSettings__header">Room Settings</span>
        <span
          v-if="room.data.value && !expireWarning && !isRoomFree"
          class="RoomSettings__expiration-date"
        >
          Active until {{ roomExpirationDateTime.toFormat("MMMM d, yyyy") }}
        </span>
        <span v-if="expireWarning" class="RoomSettings__expire-warning">{{
          expireWarning
        }}</span>
      </div>
    </template>
    <template #content>
      <div v-if="room.data.value" class="RoomSettings__wrapper">
        <div
          v-if="room.data.value.status === 'ready_to_renew'"
          class="RoomSettings__renew-wrapper"
        >
          <button class="RoomSettings__renew-button" @click="handleRenewClick">
            <DashboardIconRenewOrbit />
            Renew your subscription
          </button>
        </div>
        <FormInput
          ref="nameInput"
          :initial-value="room.data.value.name"
          label="Room name"
          name="name"
        />
        <div class="RoomSettings__waiting-room-wrapper">
          <div class="RoomSettings__row">
            <p class="RoomSettings__label">
              <IconLockFunky />
              Enable instant access for everyone
            </p>
            <FormSwitch
              ref="waitingRoomInput"
              :initial-value="!room.data.value.waiting_room"
            />
          </div>
          <p class="RoomSettings__description">
            People will be able to join the meeting freely by skipping the
            waiting room. You will no longer be asked for approval until you
            deactivate the feature.
          </p>
        </div>
        <div class="RoomSettings__row">
          <p class="RoomSettings__label">
            Can the room be activated without the host?
          </p>
          <FormSwitch
            ref="hostRequiredInput"
            :initial-value="!room.data.value.host_required"
          />
        </div>
        <button class="RoomSettings__button" @click="handleSubmitForm">
          Save all settings
        </button>
      </div>
    </template>
  </CommonBottomDrawer>
</template>
<script setup lang="ts">
import { DateTime } from "luxon";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { useDashboardStore } from "~/store/dashboard";
import { RoomDetailsResponse } from "~/types";
type Input = { value: string | boolean } | null;

type Props = {
  id: string;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "renew", id: string): void;
}>();

const runtimeConfig = useRuntimeConfig();

const dashboardStore = useDashboardStore();

const nameInput = ref<Input>(null);
const hostRequiredInput = ref<Input>(null);
const waitingRoomInput = ref<Input>(null);

const room = await useAuthRequest<RoomDetailsResponse>(
  `${runtimeConfig.public.backendApiUrl}/rooms/${props.id}`,
  {},
  { immediate: false },
);

const roomExpirationDateTime = computed(() => {
  return DateTime.fromISO(room.data.value.expiration_datetime, {
    zone: "utc",
  }).toLocal();
});

const expireWarning = computed(() => {
  if (!room.data.value) {
    return "";
  }
  if (room.data.value.status === "ready_to_renew") {
    return `Expires ${roomExpirationDateTime.value.toRelativeCalendar()}`;
  }
  return "";
});

const isRoomFree = computed(() => {
  return room.data.value?.max_participants === 10;
});

const handleClose = () => {
  emit("close");
};

const handleRenewClick = () => {
  emit("renew", room.data.value.id);
};

const { validate: validateName, meta: metaName } = useForm({
  validationSchema: yup.object({
    name: yup
      .string()
      .required("Please enter a value")
      .min(3, "Please enter at least 3 characters.")
      .max(26, "Please enter no more than 26 characters.")
      .trim(),
  }),
});

// TODO move to dashboardStore and use handler to emit save event
const handleSubmitForm = async () => {
  validateName();
  if (!metaName.value.valid) {
    return;
  }
  const payload = {
    name: nameInput.value?.value,
    host_required: !hostRequiredInput.value?.value,
    waiting_room: !waitingRoomInput.value?.value,
  };
  await useAuthRequest(
    `${runtimeConfig.public.backendApiUrl}/rooms/${props.id}`,
    {
      method: "PATCH",
      body: payload,
    },
  ).then(() => {
    dashboardStore.fetchRooms();
    emit("close");
  });
};

onMounted(() => {
  room.execute();
});
</script>
<style scoped lang="scss">
.RoomSettings {
  &__header-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  &__expiration-date {
    @include LabelSmall;
    color: $color-element-dark-basic;
  }
  &__expire-warning {
    @include label-m;
    color: $color-basic-danger-500;
  }
  &__renew-wrapper {
    padding: 20px 0 32px 0;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid $color-neutral-200;
  }
  &__renew-button {
    @include body-l;
    color: $color-element-dark-strong;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__waiting-room-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__label {
    @include body-l;
    color: $color-element-dark-strong;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
  }
  &__description {
    @include body-m;
    color: $color-element-dark-medium;
  }
  &__button {
    @include label-xl;
    padding: 11px 18px;
    width: 100%;
    color: $brand-primary-500;
    cursor: pointer;
    background: none;
    border: none;
    margin-top: 32px;
  }
}
</style>
