<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1921 14.525C11.4536 13.4565 12.5418 12.7962 13.6102 13.0577C14.6787 13.3192 15.339 14.4073 15.0775 15.4758C14.816 16.5443 13.7279 17.2045 12.6594 16.943C11.5909 16.6816 10.9307 15.5934 11.1921 14.525ZM19.6554 9.38955L20.6268 9.62726C21.6953 9.88874 22.3555 10.9769 22.094 12.0453L19.717 21.7587C19.4555 22.8272 18.3674 23.4875 17.2989 23.226L5.64284 20.3735C4.57437 20.112 3.9141 19.0239 4.17558 17.9554L6.55265 8.24203C6.81413 7.17356 7.90227 6.51329 8.97074 6.77477L17.7128 8.91414L18.1882 6.97146C18.5804 5.36876 17.59 3.73655 15.9873 3.34433C14.6566 3.01867 13.2796 3.63914 12.6356 4.84049C12.3712 5.33173 11.7609 5.51182 11.2794 5.24985C10.7882 4.9855 10.6057 4.38491 10.87 3.89367C11.949 1.88251 14.2481 0.859685 16.4627 1.40166C19.1436 2.05773 20.7869 4.76599 20.1309 7.44688L19.6554 9.38955Z"
      fill="#16B07C"
    />
    <path
      d="M3.7427 2.5425L4.5327 0.7925C4.7127 0.4025 5.27269 0.4025 5.45269 0.7925L6.24269 2.5525L7.99269 3.3425C8.38269 3.5225 8.38269 4.0725 7.99269 4.2525L6.23269 5.0425L5.4427 6.7925C5.2627 7.1825 4.7127 7.1825 4.5327 6.7925L3.7427 5.0325L1.9927 4.2425C1.6027 4.0625 1.6027 3.5125 1.9927 3.3325L3.7427 2.5425Z"
      fill="#16B07C"
    />
  </svg>
</template>
