<template>
  <div :class="containerClass" class="SidebarWrapper__container">
    <div
      :class="wrapperClass"
      class="SidebarWrapper__overlay"
      @click="handleClose"
    ></div>
    <div :class="wrapperClass" class="SidebarWrapper__wrapper">
      <div class="SidebarWrapper__header">
        <button class="SidebarWrapper__back" @click="handleGoBack">
          <o-icon custom-size="mdi-24px" icon="chevron-left" />
        </button>
        <slot name="header" />
        <button class="SidebarWrapper__close" @click="handleClose">
          <IconClose />
        </button>
      </div>
      <div :class="wrapperClass" class="SidebarWrapper__content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
type Props = {
  wrapperClass?: string;
  containerClass?: string;
};
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "go-back"): void;
  (e: "close"): void;
}>();

const handleGoBack = () => {
  emit("go-back");
};

const handleClose = () => {
  emit("close");
};
</script>
<style scoped lang="scss">
.SidebarWrapper {
  &__container {
    display: none;
    &.open {
      display: initial;
    }
    @include custom-min-width(1163px) {
      display: initial;
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-basic-overlay;
    z-index: 2;
    @include custom-min-width(1163px) {
      &.settings {
        display: none;
      }
    }
  }
  &__wrapper {
    background: $color-neutral-2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    @include md {
      width: 300px;
      left: unset;
      right: 24px;
      top: 24px;
      height: calc(100vh - 48px);
      border-radius: 20px;
      overflow: hidden;
      background: $color-basic-white;
      &.settings {
        right: calc(50% - 366px);
        top: 78px;
        max-height: 538px;
      }
    }
    @include custom-min-width(1163px) {
      &.settings {
        left: max(20px, 50% - 574px);
        max-height: initial;
        top: 24px;
        z-index: 1;
      }
    }
  }
  &__content {
    padding: 24px;
    background-color: $color-basic-white;
    border-radius: 20px 20px 0 0;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    @include md {
      border-radius: unset;
      border-top: 1px solid $color-neutral-3;
    }
    @include custom-min-width(1163px) {
      &.settings {
        background: $color-neutral-050;
        border-top: none;
        padding: 24px 16px;
      }
    }
  }
  &__header {
    @include header-xs-bold;
    margin: 24px;
    color: $color-element-dark-strong;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    @include md {
      display: none;
    }
  }
  &__back {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    @include md {
      display: none;
    }
  }
  &__close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    background: none;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include md {
      display: flex;
    }
  }
}
</style>
