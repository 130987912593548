<template>
  <div class="Title__wrapper">
    <component :is="iconComponent" v-bind="{ expired: expired }" />
    <h3 class="Title__text">{{ name }}</h3>
    <slot name="label" />
  </div>
</template>
<script setup lang="ts">
import { RoomType } from "~/types";

const props = defineProps<{
  expired: boolean;
  roomType: RoomType;
  name: string;
}>();

const iconAudio = resolveComponent("DashboardIconAudio");
const iconVideo = resolveComponent("DashboardIconVideo");
const iconStreamed = resolveComponent("DashboardIconStreamed");

const iconComponent = computed(() => {
  if (!props.roomType) {
    return;
  }
  switch (props.roomType) {
    case "audio":
      return iconAudio;
    case "video":
      return iconVideo;
    case "streamed":
      return iconStreamed;
  }
});
</script>
<style scoped lang="scss">
.Title {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
    svg {
      width: 52px;
      height: 52px;
    }
  }
  &__text {
    @include header-m;
    color: $color-element-dark-strong;
  }
}
</style>
