<template>
  <div
    v-if="dashboardOnboardingStore.dashboardState"
    class="Onboarding__wrapper"
  >
    <ClientOnly>
      <Teleport
        v-if="
          dashboardOnboardingStore.dashboardState.currentStep === 'START' &&
          showOnDesktopDashboard
        "
        :to="onboardingStartProps.to"
        :disabled="onboardingStartProps.disabled"
      >
        <OnboardingStart
          @start-onboarding="dashboardOnboardingStore.nextStep"
          @skip="dashboardOnboardingStore.skipDashboardOnboarding"
        />
      </Teleport>
      <OnboardingStep
        v-if="
          dashboardOnboardingStore.dashboardState.currentStep ===
            'NEW_HOMEPAGE' && showOnDesktopDashboard
        "
        title="Your new homepage"
        wrapper-class="step-1"
        @next="dashboardOnboardingStore.nextStep"
        @skip="dashboardOnboardingStore.skipDashboardOnboarding"
      >
        <template #step>1/5</template>
        <template #text>
          Here you can find a list of your meetings. Instantly enter one of your
          rooms or plan your future meetings in one place!
        </template>
      </OnboardingStep>
      <Teleport
        v-if="
          dashboardOnboardingStore.dashboardState.currentStep === 'ROOMS' &&
          showOnDesktopDashboard
        "
        :to="roomsStepProps"
      >
        <OnboardingStep
          title="Explore rooms"
          wrapper-class="step-2"
          @next="dashboardOnboardingStore.nextStep"
          @skip="dashboardOnboardingStore.skipDashboardOnboarding"
        >
          <template #step>2/5</template>
          <template #text>
            From now on, all your meetings will take place in rooms. You can
            manage them in this section.
          </template>
        </OnboardingStep>
      </Teleport>
      <Teleport
        v-if="
          dashboardOnboardingStore.dashboardState.currentStep ===
            'YOUR_SPACE' && showOnDesktopDashboard
        "
        :to="yourSpaceStepProps"
      >
        <OnboardingStep
          title="Your space"
          wrapper-class="step-3"
          @next="dashboardOnboardingStore.nextStep"
          @skip="dashboardOnboardingStore.skipDashboardOnboarding"
        >
          <template #step>3/5</template>
          <template #text>
            This is your room, your virtual space - use it whenever you want!
            Personalize and configure your room under the
            <IconOnboardingThreeDots />
            icon.
          </template>
        </OnboardingStep>
      </Teleport>
      <Teleport
        v-if="
          dashboardOnboardingStore.dashboardState.currentStep ===
          'DIFFERENT_ROOMS'
        "
        :to="differentRoomsStepProps"
      >
        <OnboardingStep
          title="Different rooms"
          :wrapper-class="differentRoomsStepClass"
          @next="dashboardOnboardingStore.nextStep"
          @skip="dashboardOnboardingStore.skipDashboardOnboarding"
        >
          <template #step>4/5</template>
          <template #text>
            Choose the format of the rooms convenient for you: <br />
            <IconOnboardingAudio /> audio or <IconOnboardingVideo /> video.
          </template>
        </OnboardingStep>
      </Teleport>
      <Teleport
        v-if="
          dashboardOnboardingStore.dashboardState.currentStep === 'SETTINGS'
        "
        :to="avatarStepProps.to"
        :disabled="avatarStepProps.disabled"
      >
        <OnboardingStep
          title="Settings are here"
          wrapper-class="step-5"
          @next="dashboardOnboardingStore.nextStep"
          @skip="dashboardOnboardingStore.skipDashboardOnboarding"
        >
          <template #step>5/5</template>
          <template #text>
            Here you'll now find all the important actions, and it is there that
            you will be able to restart the onboarding guide!
            <span class="Onboarding__settings-info">
              <o-icon custom-size="mdi-16px" icon="information" />
              This is the end of the guide, you can turn it on again in the
              settings.
            </span>
          </template>
          <template #next-button-text>Finish</template>
        </OnboardingStep>
      </Teleport>
      <Teleport
        v-if="dashboardOnboardingStore.dashboardState.currentStep === 'SKIPPED'"
        :to="avatarStepProps.to"
        :disabled="avatarStepProps.disabled"
      >
        <OnboardingEnd />
      </Teleport>
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
import { useMediaQuery } from "@vueuse/core";
import { useDashboardOnboardingStore } from "@/store/dashboardOnboarding";
import { useDashboardStore } from "~/store/dashboard";
const dashboardOnboardingStore = useDashboardOnboardingStore();
const dashboardStore = useDashboardStore();

const isMobile = useMediaQuery("(max-width: 724px)");
const route = useRoute();

const showOnDesktopDashboard = computed(() => {
  if (!isMobile.value) {
    return route.name === "dashboard";
  }
  return true;
});

const onboardingStartProps = computed(() => {
  return {
    disabled: isMobile.value,
    to: "#dashboard-desktop-wrapper",
  } as const;
});
const roomsStepProps = computed(() => {
  if (isMobile.value) {
    return ".Dashboard__mobile";
  }
  return "#desktop-rooms-wrapper";
});

const yourSpaceStepProps = computed(() => {
  if (isMobile.value) {
    return "#mobile-rooms-wrapper";
  }
  return "#desktop-rooms-wrapper";
});

const differentRoomsStepProps = computed(() => {
  if (isMobile.value) {
    return "#mobile-add-room-button";
  }
  return "#desktop-add-room-button";
});

const differentRoomsStepClass = computed(() => {
  const roomsLengthClass =
    dashboardStore.roomsWithSessions.length % 2 === 0 ? "" : "odd";
  return `step-4 ${roomsLengthClass}`;
});

const avatarStepProps = computed(() => {
  return {
    disabled: !isMobile.value,
    to: "#mobile-profile-wrapper",
  };
});
</script>
<style scoped lang="scss">
.Onboarding {
  &__wrapper {
    z-index: 3;
  }
  &__settings-info {
    @include BodyMedium;
    color: $color-element-light-medium;
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-top: 12px;
  }
}
</style>
