<template>
  <div class="Dashboard__container">
    <div id="mobile-profile-wrapper" class="Dashboard__hero">
      <NuxtLink to="/dashboard" class="Dashboard__logo">
        <IconKingsConference />
      </NuxtLink>
      <div class="Dashboard__profile">
        <DashboardProfile
          v-if="dashboardStore.userProfile"
          :username="dashboardStore.userProfile.username"
          :avatar-url="dashboardStore.userProfile.avatar_url"
          @click="dashboardStore.handleSettingsOpen"
        />
      </div>
    </div>
    <div class="Dashboard__main">
      <div class="Dashboard__floating-schedule-wrapper">
        <DashboardActionButtonFloatingSchedule
          @click="dashboardStore.handleScheduleWithRoomPickerOpen"
        />
      </div>
      <slot />
    </div>
    <div class="Dashboard__modals-wrapper">
      <OnboardingBetaEnded />
      <OnboardingDashboard />
      <Support />
      <CommonUnsupportedBrowserModal
        v-if="supportedBrowser.showModal"
        @close="supportedBrowser.handleModalClose"
      />
      <ClientOnly>
        <DashboardAddRoom v-if="dashboardStore.searchParamsState.addRoom" />
      </ClientOnly>
      <DashboardInstantMeeting
        v-if="
          dashboardStore.drawerState.status === 'INSTANT_MEETING_OPEN' &&
          dashboardStore.roomsWithSessions
        "
        :rooms="dashboardStore.roomsWithSessions"
        @close="dashboardStore.handleDrawerClose"
        @add-room="handleAddMoreRoomsClick"
      />
      <ClientOnly>
        <DashboardAllCalendarEventsMobile
          v-if="dashboardStore.searchParamsState.allMeetings === 'open'"
          @close="dashboardStore.handleAllCalendarEventsClose"
          @go-back="dashboardStore.handleAllCalendarEventsClose"
          @more-click="dashboardStore.handleCalendarEventMoreOpen"
          @tile-click="dashboardStore.handleCalendarEventDetailsOpen"
          @schedule-click="dashboardStore.handleScheduleWithRoomPickerOpen"
        />
      </ClientOnly>
      <DashboardCalendarEventMore
        v-if="dashboardStore.drawerState.status === 'CALENDAR_EVENT_MORE_OPEN'"
        v-bind="dashboardStore.drawerState.calendarEvent"
        @close="dashboardStore.handleDrawerClose"
        @details-click="dashboardStore.handleCalendarEventDetailsOpen"
        @delete-click="dashboardStore.handleDeleteMeetingOpen"
        @remove-from-schedule-click="dashboardStore.handleDeleteMeetingOpen"
        @edit-click="dashboardStore.handleMeetingEditOpen"
      />
      <ClientOnly>
        <DashboardCalendarEventDetails
          v-if="
            dashboardStore.searchParamsState.meetingId &&
            dashboardStore.userProfile
          "
          :id="`${dashboardStore.searchParamsState.meetingId}`"
          :user-id="dashboardStore.userProfile.id"
          @close="dashboardStore.handleCalendarEventDetailsClose"
          @go-back="dashboardStore.handleCalendarEventDetailsClose"
          @participants-list-click="
            dashboardStore.handleCalendarEventDetailsAllParticipantsOpen
          "
          @delete-click="dashboardStore.handleDeleteMeetingOpen"
          @remove-from-schedule-click="dashboardStore.handleDeleteMeetingOpen"
          @invite-click="dashboardStore.handleMeetingInviteUsersOpen"
          @edit-click="dashboardStore.handleMeetingEditOpen"
        />
      </ClientOnly>
      <ClientOnly>
        <DashboardCalendarEventParticipants
          v-if="
            dashboardStore.searchParamsState.meetingId &&
            dashboardStore.searchParamsState.allParticipants
          "
          :meeting-id="`${dashboardStore.searchParamsState.allParticipants}`"
          @close="dashboardStore.handleCalendarEventDetailsAllParticipantsClose"
        />
      </ClientOnly>
      <DashboardSettings
        v-if="dashboardStore.userProfile"
        :username="dashboardStore.userProfile.username"
        :avatar-url="dashboardStore.userProfile.avatar_url"
        :name="dashboardStore.userProfile.name"
        @close="dashboardStore.handleSettingsClose"
        @go-back="dashboardStore.handleSettingsClose"
        @schedule-meeting="dashboardStore.handleScheduleWithRoomPickerOpen"
      />
      <DashboardEnterRoom
        v-if="dashboardStore.drawerState.status === 'ENTER_ROOM_OPEN'"
        v-bind="dashboardStore.drawerState.room"
        @close="dashboardStore.handleDrawerClose"
        @schedule-click="dashboardStore.handleScheduleOpen"
      />
      <ClientOnly>
        <DashboardRoomSettings
          v-if="dashboardStore.drawerState.status === 'ROOM_SETTINGS_OPEN'"
          :id="dashboardStore.drawerState.id"
          @close="dashboardStore.handleDrawerClose"
          @renew="dashboardStore.handleRenewOpen"
        />
      </ClientOnly>
      <ClientOnly>
        <DashboardRoomRemove
          v-if="dashboardStore.drawerState.status === 'ROOM_REMOVE_OPEN'"
          :room-id="dashboardStore.drawerState.id"
          @close="dashboardStore.handleDrawerClose"
          @renew="dashboardStore.handleRenewOpen"
          @remove="dashboardStore.handleRemoveRoom"
        />
      </ClientOnly>
      <ClientOnly>
        <DashboardRoomOfferRenew
          v-if="dashboardStore.searchParamsState.renewRoomId"
          :room-id="`${dashboardStore.searchParamsState.renewRoomId}`"
          @close="dashboardStore.handleRenewClose"
        />
      </ClientOnly>
      <ClientOnly>
        <DashboardMeetingInviteUsers
          v-if="
            dashboardStore.drawerState.status === 'MEETING_INVITE_USERS_OPEN'
          "
          :meeting-id="dashboardStore.drawerState.id"
          @close="dashboardStore.handleDrawerClose"
        />
      </ClientOnly>
      <ClientOnly>
        <DashboardEditMeeting
          v-if="dashboardStore.drawerState.status === 'MEETING_EDIT_OPEN'"
          :meeting-id="dashboardStore.drawerState.meetingId"
          :name="dashboardStore.drawerState.name"
          :start-datetime="dashboardStore.drawerState.startDatetime"
          :end-datetime="dashboardStore.drawerState.endDatetime"
          :room-id="dashboardStore.drawerState.roomId"
          :repeatability="dashboardStore.drawerState.repeatability"
          @close="dashboardStore.handleDrawerClose"
          @calendar-event-details-close="
            dashboardStore.handleCalendarEventDetailsClose
          "
        />
      </ClientOnly>
      <DashboardDeleteMeeting
        v-if="dashboardStore.deleteDrawerState.status === 'DELETE_MEETING_OPEN'"
        :calendar-event-id="dashboardStore.deleteDrawerState.calendarEventId"
        :meeting-id="dashboardStore.deleteDrawerState.meetingId"
        :enable-owner-delete="
          dashboardStore.deleteDrawerState.enableOwnerDelete
        "
        @close="dashboardStore.handleDeleteDrawerClose"
        @delete-all-meetings="dashboardStore.handleDeleteMeeting"
        @delete-calendar-event="dashboardStore.handleDeleteCalendarEvent"
        @reject-invitation="dashboardStore.handleRejectInvitation"
      />
      <ClientOnly>
        <DashboardScheduleMeeting
          v-if="dashboardStore.drawerState.status === 'SCHEDULE_OPEN'"
          :room-id="dashboardStore.drawerState.id"
          :max-duration-in-sec="dashboardStore.drawerState.maxDurationInSec"
          @close="dashboardStore.handleDrawerClose"
        />
      </ClientOnly>
      <ClientOnly>
        <DashboardScheduleMeetingWithRoomPicker
          v-if="
            dashboardStore.drawerState.status ===
            'SCHEDULE_WITH_ROOM_PICKER_OPEN'
          "
          @close="dashboardStore.handleDrawerClose"
        />
      </ClientOnly>
      <ClientOnly>
        <DashboardRoomPersonalizeAfterCheckout
          v-if="dashboardStore.searchParamsState.checkoutSubscriptionId"
          :subscription-id="`${dashboardStore.searchParamsState.checkoutSubscriptionId}`"
          @close="dashboardStore.handleCheckoutSubscriptionClose"
        />
      </ClientOnly>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useDashboardStore } from "~/store/dashboard";
import { useSupportedBrowserStore } from "~/store/supportedBrowser";

useHead({
  htmlAttrs: {
    lang: "en",
  },
  meta: [
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    },
  ],
});

const supportedBrowser = useSupportedBrowserStore();
const dashboardStore = useDashboardStore();

const handleAddMoreRoomsClick = () => {
  navigateTo("/dashboard/rooms");
  dashboardStore.handleDrawerClose();
};
</script>
<style lang="scss" scoped>
body {
  margin: 0;
}

.Dashboard {
  &__floating-schedule-wrapper {
    display: none;
    @include md {
      position: absolute;
      right: 20px;
      bottom: 22px;
      display: initial;
      z-index: 2;
    }
    @include custom-min-width(1163px) {
      display: none;
    }
  }
  &__container {
    background: $brand-primary-100;
    height: 100dvh;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include md {
      background: $color-basic-white;
      height: initial;
      overflow: auto;
    }
  }
  &__hero {
    width: 100%;
    max-width: 745px;
    height: 96px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0 20px;
    @include md {
      padding: 20px 0;
      height: initial;
    }
    @include custom-min-width(1163px) {
      display: none;
    }
  }
  &__logo {
    display: flex;
  }
  &__profile {
  }
  &__main {
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    background: $color-basic-white;
    width: 100%;
    height: calc(100dvh - 96px);
    height: calc(100vh - 96px);
    border-radius: 20px 20px 0 0;
    @include md {
      height: calc(100dvh - 90px);
      height: calc(100vh - 90px);
      border-radius: unset;
    }
    @include custom-min-width(1163px) {
      height: 100dvh;
      height: 100vh;
    }
  }
}
</style>
