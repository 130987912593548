<template>
  <div class="Filter__wrapper" :class="wrapperClass">
    <button
      class="Filter__button"
      :class="{ active: selected === 'monthly' }"
      @click="handleMonthlyClick"
    >
      Monthly
    </button>
    <span class="Filter__separator"></span>
    <div class="Filter__annual">
      <button
        class="Filter__button"
        :class="{ active: selected === 'annual' }"
        @click="handleAnnualClick"
      >
        Annualy
      </button>
      <span v-if="annualDiscount && annualDiscount > 0" class="Filter__discount"
        >-{{ annualDiscount }}%</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
type Props = {
  selected: string;
  annualDiscount?: number;
  wrapperClass?: string;
};
defineProps<Props>();

const emit = defineEmits<{
  (e: "select", value: "monthly" | "annual"): void;
}>();

const handleMonthlyClick = () => {
  emit("select", "monthly");
};

const handleAnnualClick = () => {
  emit("select", "annual");
};
</script>

<style scoped lang="scss">
.Filter {
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    &.audio {
      color: $brand-tertiary-500;
    }
    &.video {
      color: $brand-primary-500;
    }
  }

  &__button {
    @include TitleSmall;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 64px;
    color: inherit;
    &.active {
      @include BodyLarge;
      color: $color-element-dark-medium;
    }
  }

  &__separator {
    width: 2px;
    height: 20px;
    background: $color-element-dark-basic;
  }

  &__annual {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__discount {
    @include LabelMedium;
    padding: 4px 6px;
    color: $color-basic-success-500;
    border-radius: 8px;
    border: 1px solid $color-basic-success-500;
  }
}
</style>
