<template>
  <CommonDetailsWrapper v-if="roomData" wrapper-class="checkout-personalize">
    <template #customHeader> Personalize your room </template>
    <template #content>
      <div class="ScheduleMeeting__wrapper">
        <div v-show="step === 'INITIAL'" class="ScheduleMeeting__step-2">
          <div class="ScheduleMeeting__illustration-wrapper">
            <IllustrationActionSuccess />
            <h3 class="ScheduleMeeting__illustration-heading">
              {{ headingText }}
            </h3>
            <p class="ScheduleMeeting__illustration-description">
              {{ descriptionText }}
            </p>
          </div>
          <div>
            <FormInput
              ref="nameInput"
              label="Room name"
              name="name"
              :initial-value="roomData.name"
            />
            <div class="RoomSettings__waiting-room-wrapper">
              <div class="RoomSettings__row">
                <p class="RoomSettings__label">
                  <IconLockFunky />
                  Enable instant access for everyone
                </p>
                <FormSwitch
                  ref="waitingRoomInput"
                  :initial-value="!roomData.waiting_room"
                />
              </div>
              <p class="RoomSettings__description">
                People will be able to join the meeting freely by skipping the
                waiting room. You will no longer be asked for approval until you
                deactivate the feature.
              </p>
            </div>
            <div class="RoomSettings__row">
              <p class="RoomSettings__label">
                Can the room be activated without the host?
              </p>
              <FormSwitch
                ref="hostRequiredInput"
                :initial-value="!roomData.host_required"
              />
            </div>
          </div>
        </div>
        <div
          v-show="step === 'PAYMENT_ERROR' || step === 'EDIT_ERROR'"
          class="ScheduleMeeting__step-4"
        >
          <div class="ScheduleMeeting__illustration-wrapper">
            <DashboardIllustrationError />
            <h3 class="ScheduleMeeting__illustration-heading">
              Something went wrong
            </h3>
            <p class="ScheduleMeeting__illustration-description">
              {{
                step === "EDIT_ERROR"
                  ? "We couldn't update your room settings. Please try again."
                  : "Payment error"
              }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div v-show="step === 'INITIAL'" class="ScheduleMeeting__button-wrapper">
        <button class="ScheduleMeeting__button secondary" @click="handleClose">
          Skip
        </button>
        <button class="ScheduleMeeting__button" @click="handleSubmitForm">
          Finish
        </button>
      </div>
      <div
        v-show="step === 'PAYMENT_ERROR' || step === 'EDIT_ERROR'"
        class="ScheduleMeeting__button-wrapper"
      >
        <button class="ScheduleMeeting__button" @click="handleClose">
          Return to home screen
        </button>
      </div>
    </template>
  </CommonDetailsWrapper>
  <CommonDetailsWrapper v-if="step === 'PAYMENT_ERROR'">
    <template #customHeader> Payment failed </template>
    <template #content>
      <div class="ScheduleMeeting__wrapper">
        <div v-show="step === 'PAYMENT_ERROR'" class="ScheduleMeeting__step-4">
          <div class="ScheduleMeeting__illustration-wrapper">
            <DashboardIllustrationError />
            <h3 class="ScheduleMeeting__illustration-heading">
              Something went wrong
            </h3>
            <p class="ScheduleMeeting__illustration-description">
              Oops! Looks like there was an issue with your payment for this
              room purchase. Please try again!
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="ScheduleMeeting__button-wrapper">
        <button class="ScheduleMeeting__button" @click="handleClose">
          Return to home screen
        </button>
      </div>
    </template>
  </CommonDetailsWrapper>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { RoomDetailsResponse, CheckPaymentResponse } from "~/types";
import { useDashboardStore } from "~/store/dashboard";

type Props = {
  subscriptionId: string;
};
type Input = { value: string } | null;

const runtimeConfig = useRuntimeConfig();

const analytics = useAnalytics();

const props = defineProps<Props>();
const dashboardStore = useDashboardStore();

const nameInput = ref<Input>(null);
const hostRequiredInput = ref<Input>(null);
const waitingRoomInput = ref<Input>(null);

const paymentData = ref<{
  status: "INITIAL" | "LOADING" | "LOADED";
  data?: CheckPaymentResponse;
}>({ status: "INITIAL" });

const roomData = ref<RoomDetailsResponse | null>(null);

const fetchPaymentData = async () => {
  paymentData.value.status = "LOADING";
  return await useAuthRequest<CheckPaymentResponse>(
    `${runtimeConfig.public.backendApiUrl}/rooms/check_payment?subscription_id=${props.subscriptionId}`,
  ).then((res) => {
    if (res.data.value) {
      paymentData.value.data = res.data.value;
      paymentData.value.status = "LOADED";
    }
    if (res.error) {
      paymentData.value.status = "LOADED";
    }
  });
};

const fetchRoomData = async () => {
  if (paymentData.value.data?.payment_status !== "PAID") {
    return;
  }
  return await useAuthRequest<RoomDetailsResponse>(
    `${runtimeConfig.public.backendApiUrl}/rooms/${paymentData.value.data.room_id}`,
  ).then(async (res) => {
    if (!res.error.value && res.data.value) {
      roomData.value = res.data.value;

      await analytics.roomPurchase({ roomId: res.data.value.id });
    }
  });
};

const headingText = computed(() => {
  if (
    paymentData.value.status !== "LOADED" ||
    paymentData.value.data?.payment_status !== "PAID"
  ) {
    return "";
  }
  if (paymentData.value.data.is_new_room_purchase) {
    return "You've got a new room!";
  }
  return "You've renewed your room!";
});

const descriptionText = computed(() => {
  if (
    paymentData.value.status !== "LOADED" ||
    paymentData.value.data?.payment_status !== "PAID"
  ) {
    return "";
  }
  if (paymentData.value.data.is_new_room_purchase) {
    return "A room is your own meeting space. You can personalize the name, so it is even more yours.";
  }
  return "The name of your room and all personalized settings have been saved. You can change them at any time, either now or later in the settings.";
});

const emit = defineEmits<{
  (e: "close"): void;
}>();

const handleClose = () => {
  emit("close");
};

const step = ref<"INITIAL" | "PAYMENT_ERROR" | "EDIT_ERROR">("INITIAL");

const { validate: validateName, meta: metaName } = useForm({
  validationSchema: yup.object({
    name: yup
      .string()
      .required("Please enter a value")
      .min(3, "Please enter at least 3 characters.")
      .max(26, "Please enter no more than 26 characters.")
      .trim(),
  }),
});

// TODO move to dashboardStore and use handler to emit save event
const handleSubmitForm = async () => {
  if (!roomData.value) {
    return;
  }
  validateName();
  if (!metaName.value.valid) {
    return;
  }
  const payload = {
    name: nameInput.value?.value,
    host_required: !hostRequiredInput.value?.value,
    waiting_room: !waitingRoomInput.value?.value,
  };
  await useAuthRequest(
    `${runtimeConfig.public.backendApiUrl}/rooms/${roomData.value.id}`,
    {
      method: "PATCH",
      body: payload,
    },
  ).then((res) => {
    if (res.error.value) {
      step.value = "EDIT_ERROR";
    }
    if (res.data.value) {
      dashboardStore.fetchRooms();
      emit("close");
    }
  });
};

watch(
  () => paymentData.value.data?.payment_status,
  (newValue) => {
    if (newValue === "FAILED") {
      step.value = "PAYMENT_ERROR";
    }
    if (newValue === "PROCESSING") {
      setTimeout(() => {
        fetchPaymentData();
      }, 5000);
    }
    if (newValue === "PAID") {
      fetchRoomData();
    }
  },
);

onMounted(() => {
  setTimeout(() => {
    fetchPaymentData();
  });
});
</script>
<style scoped lang="scss">
.RoomSettings {
  &__header-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__waiting-room-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
  }
  &__label {
    @include body-l;
    color: $color-element-dark-strong;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
  }
  &__description {
    @include body-m;
    color: $color-element-dark-medium;
  }
  &__button {
    @include label-xl;
    padding: 11px 18px;
    width: 100%;
    color: $brand-primary-500;
    cursor: pointer;
    background: none;
    border: none;
    margin-top: 32px;
  }
}
.ScheduleMeeting {
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    height: calc(100% - 16px);
    min-height: calc(100% - 16px);
  }
  &__close {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__step-1 {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  &__step-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__invite-header {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    margin-bottom: 16px;
    align-items: baseline;
  }
  &__invite-header-text {
    @include header-s;
    color: $color-element-dark-strong;
  }
  &__invite-header-description {
    @include body-l;
    color: $color-element-dark-medium;
  }
  &__illustration-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
  }
  &__illustration-heading {
    @include header-m;
    color: $color-element-dark-strong;
    margin-top: 4px;
    margin-bottom: 16px;
  }
  &__illustration-description {
    @include body-l;
    color: $color-element-dark-medium;
    text-align: center;
  }
  &__share-wrapper {
    margin-top: auto;
  }
  &__button-wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    width: 100%;
    padding: 20px;
    border-top: 1px solid $color-neutral-300;
  }
  &__button {
    @include button-primary;
    display: row;
    column-gap: 8px;
    &.secondary {
      background: none;
      color: $color-element-dark-strong;
      border: 1px solid $color-neutral-700;
    }
    &.disabled {
      pointer-events: none;
      background: $color-neutral-200;
      color: $color-element-dark-disabled;
    }
  }
  &__participants-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: 0;
    padding: 0;
    margin-top: 24px;
  }
  &__participants-list-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
