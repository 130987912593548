<template>
  <CommonDetailsWrapper wrapper-class="add-room" @close="handleClose">
    <template #customHeader>
      <div class="AddRoom__header">
        <button v-if="step > 1" class="AddRoom__back" @click="handleBack">
          <o-icon icon="chevron-left" custom-size="mdi-24px" />
        </button>
        <h2 class="AddRoom__title">Add a room</h2>
        <span class="AddRoom__step">{{ step }}/2</span>
        <button class="AddRoom__close" @click="handleClose">
          <o-icon icon="close" custom-size="mdi-24px" />
        </button>
      </div>
    </template>
    <template #content>
      <div class="AddRoom__content">
        <DashboardAddRoomSelection
          v-if="step === 1"
          v-bind="lowestMonthlyPrices"
          @choose-room-type="handleChooseRoomType"
        />
        <DashboardAddRoomOfferSelection
          v-if="step === 2 && offersByChoosenRoomType.length"
          :offers="offersByChoosenRoomType"
        />
      </div>
    </template>
    <template v-if="step === 2" #footer>
      <div class="AddRoom__button-container">
        <span v-if="submitError" class="AddRoom__error">
          Please choose the offer
        </span>
        <button
          class="AddRoom__button"
          :disabled="disablePayButton"
          @click="handlePay"
        >
          <DashboardIconKingspay /> Pay now with KingsPay
        </button>
      </div>
    </template>
  </CommonDetailsWrapper>
</template>
<script setup lang="ts">
import { useCurrencyStore } from "~/store/currency";
import { useDashboardStore } from "~/store/dashboard";

const dashboardStore = useDashboardStore();
const currencyStore = useCurrencyStore();

const submitError = ref(false);
const disablePayButton = ref(false);

watch(
  () => `${dashboardStore.searchParamsState.offerId}`,
  (newValue) => {
    if (newValue) {
      submitError.value = false;
    }
  },
);

const offersByChoosenRoomType = computed(() => {
  if (
    !dashboardStore.roomOfferTypes ||
    !dashboardStore.searchParamsState.addRoom
  ) {
    return [];
  }

  return dashboardStore.roomOfferTypes.filter(
    (offer) => offer.room_type === dashboardStore.searchParamsState.addRoom,
  );
});

const handleClose = () => {
  dashboardStore.handleClearRoomOffer();
  dashboardStore.handleAddRoomClose();
};

const handleChooseRoomType = (type: "audio" | "video") => {
  dashboardStore.handleAddRoomChooseRoomType(type);
};

const handleBack = () => {
  dashboardStore.handleClearRoomOffer();
  dashboardStore.handleAddRoomOpen();
};

type LowestMonthlyPrices = {
  priceAudio: number | undefined;
  priceVideo: number | undefined;
};
const lowestMonthlyPrices = computed<LowestMonthlyPrices>(() => {
  if (!dashboardStore.roomOfferTypes) {
    return {
      priceAudio: undefined,
      priceVideo: undefined,
    };
  }

  let priceAudio: number | undefined;
  let priceVideo: number | undefined;

  ["audio", "video"].forEach((roomType) => {
    const filteredOffers = dashboardStore.roomOfferTypes
      .filter((offer) => offer.room_type === roomType)
      .map((offer) => {
        const offers = offer.offers.filter(
          (o) => o.currency === currencyStore.defaultCurrency.currency.code,
        );
        return {
          ...offer,
          offers,
        };
      });

    if (!filteredOffers?.length) {
      return;
    }

    const lowestPrice = filteredOffers.reduce((acc, curr) => {
      if (!curr.offers.length) {
        return acc;
      }

      const price = curr.offers.reduce((offerPrice, offer) => {
        const monthlyPrice =
          offer.subscription_type === "annual" ? offer.price / 12 : offer.price;
        return monthlyPrice < offerPrice ? monthlyPrice : offerPrice;
      }, curr.offers[0].price);

      return price < acc ? price : acc;
    }, filteredOffers[0].offers[0].price);

    if (roomType === "audio") {
      priceAudio = lowestPrice;
    } else {
      priceVideo = lowestPrice;
    }
  });

  return {
    priceAudio,
    priceVideo,
  };
});

const handlePay = async () => {
  if (
    !dashboardStore.searchParamsState.offerId ||
    !currencyStore.defaultCurrency
  ) {
    submitError.value = true;
    return;
  }
  submitError.value = false;
  disablePayButton.value = true;
  await dashboardStore.handleCreateRoom(
    `${dashboardStore.searchParamsState.offerId}`,
    currencyStore.defaultCurrency.currency.code,
  );
  disablePayButton.value = false;
};

const step = computed(() => {
  const params = dashboardStore.searchParamsState;
  if (params.addRoom === "audio" || params.addRoom === "video") {
    return 2;
  }
  if (params.addRoom === "open") {
    return 1;
  }
  return 0;
});
</script>
<style scoped lang="scss">
.AddRoom {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 0 -12px 0 -12px;
    @include md {
      margin: -12px -12px 0 -12px;
    }
  }
  &__title {
    @include TitleMedium;
    color: $color-element-dark-strong;
  }
  &__step {
    @include LabelMedium;
    color: $color-element-dark-medium;
    border-radius: 12px;
    padding: 8px 12px;
    background: $color-neutral-200;
  }
  &__close,
  &__back {
    margin-left: auto;
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &__back {
    right: unset;
    left: 0;
  }
  &__content {
    padding-top: 20px;
  }
  &__button-container {
    padding: 20px;
  }
  &__error {
    @include BodySmall;
    color: $color-basic-danger-500;
    display: flex;
    padding: 8px 0;
  }
  &__button {
    @include button-primary;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    &:disabled {
      pointer-events: none;
      background: $color-neutral-200;
    }
  }
}
</style>
