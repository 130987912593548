<template>
  <CommonBottomDrawer @close="handleClose">
    <template #header> Delete the meeting </template>
    <template #content>
      <div class="DeleteMeeting__wrapper">
        <p v-if="enableOwnerDelete" class="DeleteMeeting__description">
          Do you want to delete just this event from the calendar or all
          scheduled meetings? All invited participants will receive a deletion
          notification.
        </p>
        <p v-if="!enableOwnerDelete" class="DeleteMeeting__description">
          Do you want to delete just this event from your calendar or all
          scheduled meetings?
        </p>
        <div class="DeleteMeeting__buttons">
          <button
            class="DeleteMeeting__button danger"
            @click="handleDeleteThisEventClick"
          >
            {{
              enableOwnerDelete
                ? "Delete this event"
                : "Remove this event from schedule"
            }}
          </button>
          <button
            class="DeleteMeeting__button"
            @click="handleDeleteAllMeetingsClick"
          >
            {{
              enableOwnerDelete ? "Delete all meetings" : "Remove all meetings"
            }}
          </button>
        </div>
      </div>
    </template>
  </CommonBottomDrawer>
</template>
<script setup lang="ts">
type Props = {
  meetingId: string;
  calendarEventId: string;
  enableOwnerDelete: boolean;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "delete-calendar-event", calendarEventId: string): void;
  (e: "delete-all-meetings", meetingId: string): void;
  (
    e: "reject-invitation",
    id: string,
    rejectionType: "calendar_event" | "meeting",
  ): void;
}>();

const handleClose = () => {
  emit("close");
};

const handleDeleteThisEventClick = () => {
  if (props.enableOwnerDelete) {
    return emit("delete-calendar-event", props.calendarEventId);
  }
  return emit("reject-invitation", props.calendarEventId, "calendar_event");
};
const handleDeleteAllMeetingsClick = () => {
  if (props.enableOwnerDelete) {
    return emit("delete-all-meetings", props.meetingId);
  }
  return emit("reject-invitation", props.meetingId, "meeting");
};
</script>
<style scoped lang="scss">
.DeleteMeeting {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
  &__description {
    @include body-m;
    color: $color-element-dark-medium;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  &__button {
    @include button-primary;
    background: none;
    color: $color-basic-danger-500;
    &.danger {
      background: $color-basic-danger-500;
      color: $color-element-light-strong;
    }
  }
}
</style>
