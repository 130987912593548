<template>
  <CommonBottomDrawer @close="handleClose">
    <template #header> Remove room </template>
    <template #content>
      <div class="RoomRemove__wrapper">
        <p class="RoomRemove__description">
          Do you want to remove this room? You can now renew your room and save
          link. After removing, you must buy a new room if you want to use it
          for meetings later.
        </p>
        <div class="RoomRemove__buttons">
          <button class="RoomRemove__button" @click="handleRenewClick">
            Renew
          </button>
          <button class="RoomRemove__button danger" @click="handleRemoveClick">
            Remove this room
          </button>
        </div>
      </div>
    </template>
  </CommonBottomDrawer>
</template>
<script setup lang="ts">
type Props = {
  roomId: string;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "renew", roomId: string): void;
  (e: "remove", roomId: string): void;
}>();

const handleClose = () => {
  emit("close");
};

const handleRenewClick = () => {
  return emit("renew", props.roomId);
};
const handleRemoveClick = () => {
  return emit("remove", props.roomId);
};
</script>
<style scoped lang="scss">
.RoomRemove {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
  &__description {
    @include body-m;
    color: $color-element-dark-medium;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  &__button {
    @include button-primary;
    &.danger {
      background: none;
      color: $color-basic-danger-500;
    }
  }
}
</style>
