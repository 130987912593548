<template>
  <div class="Search__wrapper">
    <div class="FormInput__container">
      <o-field class="FormInput__wrapper search">
        <template #label>{{ label }} </template>
        <o-input
          v-model="modelValue"
          type="text"
          icon="magnify"
          icon-right="close"
          icon-right-clickable
          @icon-right-click="clearInput"
          @update:modal-value="emitUpdateModelValue"
        ></o-input>
      </o-field>
      <div class="Search__row">
        <IconKingschatLongLogo />
        <p class="Search__description">
          Search through your friends on&nbsp;KingsChat
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
type Value = string | number;

type Props = {
  label: string;
  initialValue?: Value;
};
const props = defineProps<Props>();
const modelValue = ref(props.initialValue);
const emit = defineEmits(["update:modelValue"]);
const emitUpdateModelValue = (value: Value) => {
  emit("update:modelValue", value);
};

const clearInput = () => {
  modelValue.value = "";
};

defineExpose({ value: modelValue });
</script>
<style scoped lang="scss">
.Search {
  &__wrapper {
    padding: 16px;
    border-radius: 12px;
    background: $color-neutral-100;
  }
  &__row {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-top: 12px;
  }
  &__description {
    @include body-m;
    color: $color-element-dark-basic;
  }
}
</style>
