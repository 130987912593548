<template>
  <div>
    <div class="DetailsWrapper__overlay" @click="handleClose"></div>
    <div class="DetailsWrapper__wrapper" :class="wrapperClass">
      <div v-if="$slots.header" class="DetailsWrapper__header">
        <button class="DetailsWrapper__back" @click="handleGoBack">
          <o-icon custom-size="mdi-24px" icon="chevron-left" />
        </button>
        <slot name="header" />
        <button class="DetailsWrapper__close" @click="handleClose">
          <IconClose />
        </button>
      </div>
      <div v-if="$slots.customHeader" class="DetailsWrapper__custom-header">
        <div class="DetailsWrapper__header">
          <slot name="customHeader" />
        </div>
      </div>
      <div ref="containerRef" class="DetailsWrapper__content">
        <slot name="content" />
      </div>
      <div v-if="$slots.footer" class="DetailsWrapper__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const containerRef = ref<HTMLElement | null>(null);
type Props = {
  wrapperClass?: string;
};

defineProps<Props>();

const emit = defineEmits<{
  (e: "go-back"): void;
  (e: "close"): void;
}>();

defineExpose({ containerRef });

const handleGoBack = () => {
  emit("go-back");
};

const handleClose = () => {
  emit("close");
};
</script>
<style scoped lang="scss">
.DetailsWrapper {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-basic-overlay;
    z-index: 2;
  }
  &__wrapper {
    background: $color-neutral-2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    @include md {
      width: 580px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: initial;
      max-height: min(680px, 100vh);
      border-radius: 20px;
      overflow: hidden;
      background: $color-neutral-1;
      &.schedule {
        max-height: min(768px, 100vh);
        height: 100%;
        width: 414px;
      }
      &.checkout-personalize {
        max-height: min(768px, 100vh);
        height: 100%;
        width: 480px;
      }
      &.add-room {
        max-height: min(800px, 100vh);
        height: 100%;
        width: 480px;
      }
    }
  }
  &__content {
    padding: 0 24px 24px 24px;
    background-color: $color-neutral-1;
    border-radius: 20px 20px 0 0;
    flex: 1;
    overflow-y: auto;
    width: 100%;
    scrollbar-gutter: stable;
    @include md {
      padding: 0 32px 32px 32px;
      border-radius: unset;
    }
  }
  &__custom-header {
    border-bottom: 1px solid $color-neutral-200;
  }
  &__header {
    @include header-xs-bold;
    margin: 20px;
    color: $color-element-dark-strong;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    @include md {
      margin: 32px 32px 20px 32px;
    }
  }
  &__footer {
  }
  &__back {
    position: absolute;
    left: 0;
    top: 0;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    z-index: 1;
    @include md {
      display: none;
    }
  }
  &__close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    background: none;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include md {
      display: flex;
    }
  }
}
</style>
